import { FC } from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import { UnstyledButton } from "@mantine/core";
import Text from "../Text";
import Stack from "../Stack";
import { OrderTypeEnumKeys } from "types";

interface Props {
  onClick: () => void;
  orderType: OrderTypeEnumKeys;
  id: string;
}

const OrderCta: FC<Props> = ({ onClick, orderType, id }) => {
  const { t } = useTranslation();
  const assetName = orderType === "SELL" ? "salesIndicatorLarge" : "purchaseIndicatorLarge";
  const labelKey = orderType === "SELL" ? "common.sell" : "common.buy";

  return (
    <UnstyledButton className={styles.orderCtaRoot} onClick={onClick} id={id}>
      <Stack gap="16px">
        <SVGAsset name={assetName} />
        <Text display="technical" size="lg" tt="uppercase">
          {t(labelKey)}
        </Text>
      </Stack>
    </UnstyledButton>
  );
};
export default OrderCta;
