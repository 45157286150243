import React, { FC, useState, useEffect, useRef, useContext, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@/components/TextField/TextField";
import SVG from "@/components/SVGAsset/SVGAsset";
import { useHistory } from "react-router-dom";
import { login } from "@/services/Login";
import { Helmet } from "react-helmet-async";
import { ErrorDialogContext } from "@/contexts/ErrorDialogContext";
import CTA from "@/components/_Reviver/atoms/CTA";
import Stack from "@/components/_Reviver/atoms/Stack";

import styles from "./styles.module.css";
import { HeadersContainer, SimpleHeader } from "@/components/_Reviver/molecules/Header";
import Heading from "@/components/_Reviver/atoms/Heading";
import Text from "@/components/_Reviver/atoms/Text";
import Spinner from "@/components/Spinner/Spinner";
import Space from "@/components/_Reviver/atoms/Space";

interface Props {
  setToken: any;
  setUserContext: any;
  userContext: any;
  setLocaleOnLogin: any;
}

const Login: FC<Props> = ({ setToken, setUserContext, userContext, setLocaleOnLogin }) => {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};

  const emailInput = useRef<HTMLInputElement | null>(null);
  const passwordInput = useRef<HTMLInputElement | null>(null);

  const history = useHistory();
  const [username, setUserName] = useState((userContext && userContext.email) || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("redirection");
    if (username === "") {
      emailInput.current!.focus();
    } else {
      passwordInput.current!.focus();
    }
  });

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    let user;
    try {
      const loginResponse = await login(username, password);
      user = loginResponse.data;
      const headers = loginResponse.headers;
      const authHeader = headers.authorization;
      setToken(authHeader);
    } catch (error) {
      setErrorDialogOptions({ message: "Innlogging feilet.", error: error as Error });
      showErrorDialog(true);
    }

    if (user) {
      setLocaleOnLogin(user);
      history.replace("/registerCompany");
      setUserContext({
        user,
      });
    } else setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - Login</title>
        <meta name="description" content="Reviver - Login" />
      </Helmet>
      <HeadersContainer>
        <SimpleHeader />
      </HeadersContainer>
      <Spinner loading={loading} />
      <div className={styles.loginRoot}>
        <div className={styles.loginContent}>
          <figure className={styles.splashImg}>
            <img src="/assets/img/splash/polymer-melt-logo.png" alt="Reviver logo" />
          </figure>

          <div className={styles.formPanel}>
            <SVG className={styles.logo} name="reviver-logo" />
            <form onSubmit={handleSubmit}>
              <Stack gap="10px">
                <Stack gap="10px">
                  <Heading order={1} styleOrder={4} tt="uppercase">
                    {t("common.login")}
                  </Heading>
                  <Text color="secondary">Velkommen tilbake, det er hyggelig å se deg igjen</Text>
                  <Space height="5px" />
                </Stack>
                <TextField
                  ref={emailInput}
                  id="txt-loginUserName"
                  label="Brukernavn"
                  defaultValue={userContext.email || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserName(e.target.value)}
                ></TextField>
                <TextField
                  ref={passwordInput}
                  id="txt-loginPassword"
                  label="Passord"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  type="password"
                ></TextField>
                {/*
                <div className="remember-me-forgot-password">
                  <CheckBox id="cb-loginRememberMe" label="Husk meg"></CheckBox>
                  <a href="https://google.com" target="_blank" rel="noreferrer">Glemt passord?</a>
                </div>
                */}
                <div className="submit">
                  <CTA id="btn-loginSubmit" type="submit" onClick={(e: SyntheticEvent) => handleSubmit(e)}>
                    {"Logg inn"}
                  </CTA>
                </div>
                {/*
          <span>Har du ikke konto? <a href="https://google.com" target="_blank" rel="noreferrer">Registrer deg nå!</a></span>
          */}
              </Stack>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
