import { useTranslation } from "react-i18next";
import Modal, { ModalCTAs, ModalContent } from "../Modal";
import Text from "../../atoms/Text";
import CTA from "../../atoms/CTA";

interface Props {
  isOpen: boolean;
  closeCancelConfirmation: () => void;
  cancelOrder: () => void;
  text: string;
}

export default function CancelOrderModal(props: Props) {
  const { t } = useTranslation();

  return (
    <Modal open={props.isOpen} onClose={() => props.closeCancelConfirmation()} headerText={t("common.confirm")}>
      <ModalContent>
        <Text>{props.text}</Text>
      </ModalContent>
      <ModalCTAs>
        <CTA id="delete-order-cancel" intent="tertiary" onClick={() => props.closeCancelConfirmation()}>
          {t("common.cancelOrder")}
        </CTA>
        <CTA intent="primary" id="delete-order-confirm" onClick={() => props.cancelOrder()}>
          {t("common.accept")}
        </CTA>
      </ModalCTAs>
    </Modal>
  );
}
