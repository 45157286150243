import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { customDropdownStyles } from "../Dropdown/Dropdown";
import "../Dropdown/Dropdown.css";

export default function DropdownMulti(props) {
  const { placeholder } = props;
  return (
    <div className="dropdown-area">
      <label>{props.label}</label>
      <Select
        styles={customDropdownStyles}
        placeholder={placeholder ? placeholder : false}
        noOptionsMessage={() => "..."}
        isMulti
        {...props}
      />
    </div>
  );
}

DropdownMulti.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  clearValue: PropTypes.func,
  dropDownClassNames: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  menuPlacement: PropTypes.string,
};
