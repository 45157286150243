import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTransactionDocIds, getDocuments } from "../../services/Documents";
import { getTransactionInvoices } from "../../services/Accounting";

import Spinner from "../../components/Spinner/Spinner";

export default function DocumentsTabContent(props) {
  const { t } = useTranslation();
  const { order } = props;
  const [documents, setDocuments] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function asyncLoadDocuments() {
      let docArray = [];
      setIsLoading(true);
      if (order.transactions) {
        let index = 0;
        for await (const trans of order.transactions) {
          //Get invoices from transactions
          try {
            const transactionInvoices = await getTransactionInvoices(trans.id);
            if (transactionInvoices) docArray.push(transactionInvoices.data);
          } catch (error) {
            //do nothing
          }

          //Get other document ids from transactions
          const transactionDocs = await getTransactionDocIds(trans.id, order.company.id);
          const documentIds = transactionDocs.data.map((doc) => {
            return doc.documentId;
          });
          //Get other documents from transaction document ids
          if (documentIds) {
            for await (const docId of documentIds) {
              const docs = await getDocuments(order.company.id, docId);
              docArray.push(docs.data);
            }
          }
          index++;

          // used to add some air in the list
          if (order.transactions.length > index) docArray.push(null);
        }
      }
      setDocuments(docArray.filter((doc) => doc));
      // setDocuments(docArray.filter((doc) => !!doc.fileType));
      setIsLoading(false);
    }
    asyncLoadDocuments();
  }, [order.company.id, order.transactions]);

  return (
    <>
      <table className="compact lines">
        <thead>
          <tr>
            <th scope="col" colSpan={3}>
              {t("dashboard.documentType")}
            </th>
            <th scope="col">{t("dashboard.fileType")}</th>
          </tr>
        </thead>

        <tbody>
          {documents &&
            documents.map((doc, index) => (
              <tr id={`document-${index}`} key={`table-${index}`}>
                {doc && (
                  <>
                    <td data-label={t("dashboard.documentType")} colSpan={3}>
                      <a
                        download={t("documents." + doc.documentType)}
                        href={`data:${doc.mediaType};base64,${doc.document}`}
                      >
                        {t("documents." + doc.documentType)}
                      </a>
                    </td>
                    <td data-label={t("dashboard.fileType")}>{doc.fileType}</td>
                  </>
                )}
                {!doc && (
                  <>
                    <td colSpan={3}></td>
                    <td>{"-"}</td>
                  </>
                )}
              </tr>
            ))}
          {documents && documents.length === 0 && (
            <tr>
              <td>{"-"}</td>
              <td></td>
              <td></td>
              <td>{"-"}</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex full center pad20">
        <Spinner loading={isLoading} inline />
      </div>
    </>
  );
}
