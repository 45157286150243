import React from "react";
import PropTypes from "prop-types";

import "./MessageBar.css";

const type = {
  Positive: "Positive",
  Warning: "Warning",
  Negative: "Negative",
};

MessageBar.type = type;

export default function MessageBar(props) {
  const type = (props.type || MessageBar.type.Positive).toLowerCase();
  const classNames = props.className || "";

  return <div className={`message-bar ${type} ${classNames} font-family-sans`}>{props.message}</div>;
}

MessageBar.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([...Object.keys(type)]),
};
