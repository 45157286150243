import React, { createContext, PropsWithChildren, useState } from "react";
import PropTypes from "prop-types";

export const ErrorDialogContext = createContext<ErrorDialogContextType | undefined>(undefined);

interface ErrorDialogOptions {
  message?: string;
  error?: Error;
}

export interface ErrorDialogContextType {
  isErrorDialogOpen: boolean;
  showErrorDialog: (shouldShow: boolean) => void;
  setErrorDialogOptions: (options: ErrorDialogOptions) => void;
  errorDialogOptions: ErrorDialogOptions;
}

interface Props {
  isErrorDialogOpen: boolean;
}

export const Provider = (props: PropsWithChildren<Props>) => {
  const { isErrorDialogOpen: initialOpen = false, children } = props;

  const [isErrorDialogOpen, showErrorDialog] = useState<boolean>(initialOpen);
  const [errorDialogOptions, setErrorDialogOptions] = useState<ErrorDialogOptions>({});

  const errorDialogContext = {
    isErrorDialogOpen,
    showErrorDialog,
    errorDialogOptions,
    setErrorDialogOptions,
  };

  return <ErrorDialogContext.Provider value={errorDialogContext}>{children}</ErrorDialogContext.Provider>;
};

export const { Consumer } = ErrorDialogContext;

Provider.propTypes = {
  isErrorDialogOpen: PropTypes.bool,
};
