import React, { useEffect, useState, useContext, useRef, Dispatch, SetStateAction } from "react";
import Grid from "@/components/Grid/Grid";
import { useTranslation } from "react-i18next";
import { getCreateOrderFromOrder, postCalculateTransportPrice, getBoxtypeValues } from "@/services/Orders";
import { getCompanyCreditById } from "@/services/Company";
import { getCountries } from "@/services/RegisterCompany";
import { calcMinAmountOfBoxes } from "@/utils/TransportUtil";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import UserContext from "@/contexts/UserContext";
import Tabs from "@/components/Tabs/Tabs";
import Tab from "@/components/Tabs/Tab";
import OrderTabContent from "@/components/_Reviver/organisms/OrderTabContent/OrderTabContent";
import Spinner from "@/components/Spinner/Spinner";
import Dropdown2 from "@/components/Dropdown2/Dropdown2";
import OrderTypeEnum from "@/constants/enums/OrderTypeEnum";
import Textfield from "@/components/TextField/TextField";
import Drawer, { DrawerFooter, DrawerInner } from "@/components/Drawer/Drawer";
import { saleOrPurchase } from "@/utils/Orders";

import OrderDetailsSell from "@/components/_Reviver/organisms/OrderDetails/OrderDetailsSell";
import OrderDetailsBuy from "@/components/_Reviver/organisms/OrderDetails/OrderDetailsBuy";
import OrderDetailsAddress from "@/components/_Reviver/organisms/OrderDetails/OrderDetailsAddress";
import CTA from "@/components/_Reviver/atoms/CTA";
import { AmountOption, BoxType, Country, CountryOption, Order, ValidationErrors } from "types";
import { PurchaseOrSellFromDrawerModalConfig } from ".";

import "./Trade.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  orderInDrawer?: Order;
  setOrderInDrawer: Dispatch<SetStateAction<Order | undefined>>;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setIsCreditPopupOpen: Dispatch<SetStateAction<boolean>>;
  amountOptionsDrawer: AmountOption[];
  isLoadingDrawer: boolean;
  setOrderCopy: Dispatch<SetStateAction<undefined>>;
  validateDelivery: (boxType: any, nrOfBoxes: any) => Promise<void>;
  addressValidationErrors: ValidationErrors;
  setAddressValidationErrors: Dispatch<SetStateAction<ValidationErrors>>;
  isLoadingNewPriceDrawer: boolean;
  selectedAmountDrawer?: AmountOption;
  setSelectedAmountDrawer: Dispatch<SetStateAction<AmountOption | undefined>>;
  purchaseOrSellFromDrawerModalConfig: PurchaseOrSellFromDrawerModalConfig;
  setPurchaseOrSellFromDrawerModalConfig: Dispatch<SetStateAction<PurchaseOrSellFromDrawerModalConfig>>;
}

export default function TradeDrawer(props: Props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany, user } = userContext;
  const {
    setIsCreditPopupOpen,
    amountOptionsDrawer = [],
    isLoadingDrawer,
    setOrderCopy,
    isOpen,
    onClose,
    setIsDrawerOpen,
    orderInDrawer,
    setOrderInDrawer,
    validateDelivery,
    addressValidationErrors,
    setAddressValidationErrors,
    isLoadingNewPriceDrawer,
    selectedAmountDrawer,
    setSelectedAmountDrawer,
    purchaseOrSellFromDrawerModalConfig,
    setPurchaseOrSellFromDrawerModalConfig,
  } = props;

  //  Drawer
  const [isAddressCBDrawerChecked, setIsAddressCBDrawerChecked] = useState(false);
  const [countryOptionsDrawer, setCountryOptionsDrawer] = useState([]);
  const [selectedCountryDrawer, setSelectedCountryDrawer] = useState<any>(null);
  const [addressDrawer, setAddressDrawer] = useState("");
  const [postCodeDrawer, setPostCodeDrawer] = useState("");
  const [placeDrawer, setPlaceDrawer] = useState("");
  const [validateAddressMessage, setValidateAddressMessage] = useState("");
  const [newTransportPriceDrawer, setNewTransportPriceDrawer] = useState("");
  const addressDrawerNodeRef = useRef(null);
  const [boxtypeOptionsDrawer, setBoxtypeOptionsDrawer] = useState([]);
  const [selectedBoxtypeDrawer, setSelectedBoxtypeDrawer] = useState<any>(null);
  const [nrOfBoxesDrawer, setNrOfBoxesDrawer] = useState<number>(0);
  const [ableToPurchase, setAbleToPurchase] = useState<boolean>(true);

  useEffect(() => {
    async function asyncGetCountries() {
      const countries = await getCountries();

      const _countryOptions = countries.data.map((country: Country) => ({
        value: country.id,
        label: country.name,
        iso3Code: country.iso3,
        iso2Code: country.iso2,
        isEu: country.isEu,
      }));
      setCountryOptionsDrawer(_countryOptions);
    }

    async function asyncGetBoxTypes() {
      const availableBoxTypes = await getBoxtypeValues();
      const _boxtypeOptions = availableBoxTypes.data.boxtypes.map((boxtype: BoxType) => ({
        value: boxtype.id,
        label: t("common.boxtypes." + boxtype.name),
        maxWeight: boxtype.maxWeight,
      }));
      setBoxtypeOptionsDrawer(_boxtypeOptions);
    }

    asyncGetCountries();
    asyncGetBoxTypes();
  }, [t]);

  async function asyncGetCreateOrderFromOrder(orderId: number, companyId: number, userId: number, priceToPay: number) {
    const response = await getCreateOrderFromOrder(orderId, companyId, userId, priceToPay);
    return response.data;
  }

  async function asyncCalculateTransportPrice(
    orderId: number,
    postCode: string,
    country: CountryOption,
    place: string,
    amount: number,
    numberOfBoxes: number | null,
    boxTypeId: number | null
  ) {
    const newPrice = await postCalculateTransportPrice(
      orderId,
      postCode ? postCode : null,
      country ? country.iso3Code : null,
      place ? place : null,
      currentCompany.companyId,
      amount,
      numberOfBoxes,
      boxTypeId
    );

    return newPrice.data;
  }

  async function onChangeSelectedOrderAmountDrawer(changeAmount: AmountOption) {
    setSelectedAmountDrawer(changeAmount);

    const newPrice =
      orderInDrawer?.id &&
      selectedCountryDrawer &&
      (await asyncCalculateTransportPrice(
        orderInDrawer.id,
        postCodeDrawer,
        selectedCountryDrawer,
        placeDrawer,
        changeAmount.value,
        null,
        null
      ));
    changePriceInDrawer(newPrice);
  }

  function resetDifferentAddressFieldsDrawer() {
    setAddressDrawer("");
    setSelectedCountryDrawer(null);
    setPostCodeDrawer("");
    setPlaceDrawer("");
    setValidateAddressMessage("");
    setNewTransportPriceDrawer("");
  }

  function onClickDifferentAddressCBDrawerHandler(event: any) {
    setIsAddressCBDrawerChecked(event.checked);
    setAbleToPurchase(!event.checked);
    // Reset textfields when un-checking CB to reduce risk mistakes in order
    if (!event.checked) resetDifferentAddressFieldsDrawer();
    setAbleToPurchase(!event.checked);
  }

  function changePriceInDrawer(newPrice: any) {
    if (newPrice?.price && newPrice?.delivery && newPrice.status === "PriceFound" && orderInDrawer?.orderCurrency) {
      setOrderInDrawer({
        ...(orderInDrawer as Order),
        priceWithFee: newPrice.price,
        orderDelivery: newPrice.delivery,
      });
      setNewTransportPriceDrawer(newPrice.price[orderInDrawer.orderCurrency].toString());
      setValidateAddressMessage("");
    } else {
      setNewTransportPriceDrawer("");
      setValidateAddressMessage(t("commonOrders.notValidRoute"));
    }
  }

  async function validateDeliveryFromDrawer() {
    if (orderInDrawer?.orderType.id === OrderTypeEnum.SELL) {
      await validateDelivery(null, null);
    } else if (orderInDrawer?.orderType.id === OrderTypeEnum.BUY) {
      await validateDelivery(selectedBoxtypeDrawer.value, nrOfBoxesDrawer);
    }
  }

  async function calcBoxtypeDefaultsDrawer(orderAmount: any, boxType: any, calculateNewTransportPrice: any) {
    setSelectedBoxtypeDrawer(boxType);
    const minBoxNr = calcMinAmountOfBoxes(orderAmount, boxType.maxWeight);
    setNrOfBoxesDrawer(minBoxNr);

    if (calculateNewTransportPrice) await validateDelivery(boxType.value, minBoxNr);
  }

  async function onChangeSelectedBoxtypeDrawer(selectedBoxType: any) {
    calcBoxtypeDefaultsDrawer(orderInDrawer?.amount, selectedBoxType, true);
  }

  async function onChangeNrOfBoxesDrawer(nrOfBoxes: number) {
    setNrOfBoxesDrawer(nrOfBoxes);
    await validateDelivery(selectedBoxtypeDrawer?.value, nrOfBoxes);
  }

  async function purchaseOrSellFromDrawerHandler(order: Order) {
    // If different address is relevant, we need to validate if we still deliver to chosen address (user can have changed input)
    if (!orderInDrawer || !order) return;
    if (
      (orderInDrawer.orderType.id === OrderTypeEnum.SELL || orderInDrawer.orderType.id === OrderTypeEnum.BUY) &&
      !currentCompany.withoutTransport
    ) {
      const amount =
        orderInDrawer.orderType.id === OrderTypeEnum.BUY ? orderInDrawer.amount : selectedAmountDrawer?.value;
      const boxType = orderInDrawer.orderType.id === OrderTypeEnum.BUY ? selectedBoxtypeDrawer?.value : null;
      const nrOfBoxes = orderInDrawer.orderType.id === OrderTypeEnum.BUY ? nrOfBoxesDrawer : null;

      const addressValidation = await asyncCalculateTransportPrice(
        orderInDrawer.id,
        postCodeDrawer,
        selectedCountryDrawer,
        placeDrawer,
        amount ?? 0,
        nrOfBoxes,
        boxType
      );
      if (addressValidation.status === "NoPriceFound") {
        setValidateAddressMessage(t("commonOrders.notValidRoute"));
        return;
      }
    }

    if (order.orderType.id === OrderTypeEnum.AUCTION) return console.log("handle autobid");
    if (!order.priceWithFee || !order.orderCurrency) return;

    const copy: any = await asyncGetCreateOrderFromOrder(
      order.id,
      currentCompany && currentCompany.companyId,
      user && user.id,
      order?.priceWithFee[order.orderCurrency]
    );

    if (copy.orderType === OrderTypeEnum.BUY && !selectedAmountDrawer) return;

    copy.companyId = currentCompany && currentCompany.companyId;
    copy.userId = user && user.id;

    if (copy.orderType === OrderTypeEnum.BUY && selectedAmountDrawer?.value) {
      //not allowed to buy if price > remaining credit for company
      const asyncGetCompanyCredit = async () => {
        const credit = await getCompanyCreditById(currentCompany.companyId);
        return credit.data;
      };
      const credit = await asyncGetCompanyCredit();
      const totalPrice = copy.price * selectedAmountDrawer.value;
      //Show warning if credit is too low, else continue as usual
      if (totalPrice > credit.remaining) {
        setIsCreditPopupOpen(true);
      } else {
        if (isAddressCBDrawerChecked) {
          copy.orderAddress = {
            address: addressDrawer,
            postCode: postCodeDrawer,
            place: placeDrawer,
            countryCodeIso3: selectedCountryDrawer?.iso3Code,
            countryCodeIso2: selectedCountryDrawer?.iso2Code,
            isEu: selectedCountryDrawer?.isEu,
          };
        }
        copy.amount = selectedAmountDrawer.value;
        copy.minimumAmount = selectedAmountDrawer.value;
        setPurchaseOrSellFromDrawerModalConfig({
          ...purchaseOrSellFromDrawerModalConfig,
          isOpen: true,
          header: t("trade.confirmationheaderbuy"),
          text: t("trade.confirmationtextbuy"),
          text2: t("trade.confirmationtext2buy"),
          text3: t("trade.confirmationtext3buy"),
          amount: `${copy.amount}kg`,
          salesitem: `${t("common.plastic." + copy.salesItem.value)}`,
          // Price can be different if new address if filled in
          price: `${isAddressCBDrawerChecked ? newTransportPriceDrawer : copy.price}`,
          currency: `${copy.orderCurrency.value}`,
          orderIdToCancel: order.id,
        });
      }
    } else {
      copy.packaging.boxtype = selectedBoxtypeDrawer.value;
      copy.packaging.numberOfBoxes = nrOfBoxesDrawer;
      setPurchaseOrSellFromDrawerModalConfig({
        ...purchaseOrSellFromDrawerModalConfig,
        isOpen: true,
        header: t("trade.confirmationheadersell"),
        text: t("trade.confirmationtextsell"),
        text2: t("trade.confirmationtext2sell"),
        text3: t("trade.confirmationtext3sell"),
        amount: `${copy.amount}kg`,
        salesitem: `${t("common.plastic." + copy.salesItem.value)}`,
        price: `${copy.price}`,
        currency: `${copy.orderCurrency.value}`,
        orderIdToCancel: order.id,
      });
    }
    setOrderCopy(copy);
  }

  function closeAndCancelDrawer() {
    setIsDrawerOpen(false);
    setAddressValidationErrors({});
  }

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <Spinner loading={isLoadingDrawer} />
        {orderInDrawer && (
          <DrawerInner>
            <div className="flex gap10 align pad20">
              <SVGAsset name={saleOrPurchase(t, orderInDrawer.orderType.id).tabSvgAsset} />
              <h3 className="no-margin">{saleOrPurchase(t, orderInDrawer.orderType.id).tabHeaderText.toUpperCase()}</h3>
            </div>
            <Tabs className="verify-customer-tabs">
              <Tab>
                <OrderTabContent order={orderInDrawer} isLoading={isLoadingNewPriceDrawer} />
              </Tab>
            </Tabs>
            <DrawerFooter>
              {orderInDrawer.orderType.id === OrderTypeEnum.SELL && (
                <OrderDetailsSell
                  orderInDrawer={orderInDrawer}
                  selectedAmountDrawer={selectedAmountDrawer}
                  amountOptionsDrawer={amountOptionsDrawer}
                  onChangeSelectedOrderAmountDrawer={onChangeSelectedOrderAmountDrawer}
                />
              )}

              {orderInDrawer.orderType.id === OrderTypeEnum.BUY && (
                <OrderDetailsBuy
                  nrOfBoxesDrawer={nrOfBoxesDrawer}
                  boxtypeOptionsDrawer={boxtypeOptionsDrawer}
                  selectedBoxtypeDrawer={selectedBoxtypeDrawer}
                  onChangeSelectedBoxtypeDrawer={onChangeSelectedBoxtypeDrawer}
                  onChangeNrOfBoxesDrawer={onChangeNrOfBoxesDrawer}
                />
              )}

              {orderInDrawer.orderType.id === OrderTypeEnum.AUCTION && (
                <Grid>
                  <Grid.Row>
                    <div className="flex gap10">
                      <SVGAsset name={saleOrPurchase(t, orderInDrawer.orderType.id).tabActionSvgAsset} />
                      <p>{saleOrPurchase(t, orderInDrawer.orderType.id).tabActionText}</p>
                    </div>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col span={4}>
                      <div className="flex align gap10">
                        <label>{t("common.amount")}</label>
                        <Dropdown2
                          id="active-orders-sidepanel-amount"
                          options={amountOptionsDrawer}
                          selected={!!selectedAmountDrawer?.value}
                          onChange={(e) => setSelectedAmountDrawer(e)}
                        />
                      </div>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <div className="flex align gap10">
                        <label>{t("commonOrders.maxPrice")}</label>
                        <Textfield style={{ width: "100px" }} id="active-orders-sidepanel-maxprice" />
                        <label>{orderInDrawer.orderCurrency} pr kg.</label>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col span={12}>
                      <strong>Your autobid</strong>
                      <div className="flex gap20">
                        <SVGAsset name="circle-questionmark" />
                        <p>{t("commonOrders.yourAutobid")}</p>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              )}

              <OrderDetailsAddress
                isAddressCBDrawerChecked={isAddressCBDrawerChecked}
                onClickDifferentAddressCBDrawerHandler={onClickDifferentAddressCBDrawerHandler}
                addressDrawerNodeRef={addressDrawerNodeRef}
                addressDrawer={addressDrawer}
                setAddressDrawer={setAddressDrawer}
                addressValidationErrors={addressValidationErrors}
                countryOptionsDrawer={countryOptionsDrawer}
                setSelectedCountryDrawer={setSelectedCountryDrawer}
                selectedCountryDrawer={selectedCountryDrawer}
                postCodeDrawer={postCodeDrawer}
                setPostCodeDrawer={setPostCodeDrawer}
                placeDrawer={placeDrawer}
                setPlaceDrawer={setPlaceDrawer}
                validateDeliveryFromDrawer={validateDeliveryFromDrawer}
                isLoadingNewPriceDrawer={isLoadingNewPriceDrawer}
                validateAddressMessage={validateAddressMessage}
              />

              <div className="flex align right gap20">
                <CTA id="active-orders-sidepanel-cancel" variant="tertiary" onClick={() => closeAndCancelDrawer()}>
                  {t("common.cancel")}
                </CTA>
                <CTA
                  intent="affirm"
                  size="lg"
                  id="active-orders-sidepanel-accept"
                  disabled={!ableToPurchase}
                  onClick={() => purchaseOrSellFromDrawerHandler(orderInDrawer)}
                >
                  {saleOrPurchase(t, orderInDrawer.orderType.id).buttonText}
                </CTA>
              </div>
            </DrawerFooter>
          </DrawerInner>
        )}
      </Drawer>
    </>
  );
}
