import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { postCreateOrder } from "@/services/Orders";
import { postCanTransportFrom } from "@/services/Transport";
import {
  createStep1SchemaSales,
  createStep1SchemaBuy,
  createStep2Schema,
  createStep2AddressSchema,
  createStep3Schema,
  createFileUploadSchema,
} from "./validation/CreateOrderValidationSchemas";
import { validateFieldsHelper } from "@/utils/ValidationHelper";
import UserContext from "@/contexts/UserContext";
import TabWiz from "@/components/TabWizard/TabWiz";
import TabsWiz from "@/components/TabWizard/TabsWiz";
import OrderDetailsBuy from "./Steps/OrderDetailsBuy";
import LocationAndDeliveryBuy from "./Steps/LocationAndDeliveryBuy";
import PackingSell from "./Steps/PackingSell";
import OrderDetailsSell from "./Steps/OrderDetailsSell";
import ImageUploadSell from "./Steps/ImageUploadSell";
import LocationAndDeliverySell from "./Steps/LocationAndDeliverySell";
import OrderTypeEnum from "@/constants/enums/OrderTypeEnum";
import { SalesStepTypeEnum, PurchaseStepTypeEnum } from "@/constants/enums/StepTypeEnum";
import getCreateOrderObject from "@/structures/CreateOrderStructure";
import { saleOrPurchase } from "@/utils/Orders";

import MessageBar from "@/components/MessageBar/MessageBar";
import Modal, { ModalCTAs, ModalContent } from "@/components/_Reviver/organisms/Modal";
import OrderModal, { OrderModalContent } from "@/components/_Reviver/organisms/Modal/OrderModal";
import OrderInformation from "@/components/_Reviver/molecules/OrderInformation";
import Group from "@/components/_Reviver/atoms/Group";
import CTA from "@/components/_Reviver/atoms/CTA";
import OrderCta from "@/components/_Reviver/atoms/OrderCTA";

import { OrderTypeEnumKeys, OrderTypeEnumValues, PurchaseStepType, SalesStepType } from "types";

interface Props {
  isChooseOrderTypeOpen: boolean;
  setIsChooseOrderTypeOpen: (isOpen: boolean) => void;
}

export default function CreateOrder(props: Props) {
  const { t } = useTranslation();

  const userContext = useContext(UserContext);
  const { currentCompany, user } = userContext;

  const [orderTemplate, setOrderTemplate] = useState({});

  // Filter
  const [filter, setFilter] = useState({
    activeCompanyId: currentCompany && currentCompany.companyId,
    pageNumber: 1,
  });

  // Create order
  const [isCreatePurchaseOrderModalOpen, setIsCreatePurchaseOrderModalOpen] = useState(false);

  const [createOrderValues, setCreateOrderValues] = useState(getCreateOrderObject());
  const [isCreateSalesOrderModalOpen, setIsCreateSalesOrderModalOpen] = useState(false);

  const createOrderValuesRef = useRef<any>();
  createOrderValuesRef.current = createOrderValues;
  const [isDifferentAddressInCreateOrderChecked, setIsDifferentAddressInCreateOrderChecked] = useState(false);
  const [differentAddressValidationMessage, setDifferentAddressValidationMessage] = useState({});
  const [createOrderValidationError, setCreateOrderValidationError] = useState({});
  const [createOrderAddressValidationError, setCreateOrderAddressValidationError] = useState({});

  const initialSteps = (n: number) =>
    Array.from({ length: n }, (_, index) => {
      return {
        key: `step${index + 1}`,
        isActive: index === 0, // Make the first step active
        isDone: false,
      };
    });

  const [salesSteps, setSalesSteps] = useState(() => initialSteps(4));
  const [currentOrderStep, setCurrentOrderStep] = useState(0);
  const [purchaseSteps, setPurchaseSteps] = useState(() => initialSteps(2));
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    // Effect changes list based on filter.
    let filteredInterval: any;

    return () => {
      clearInterval(filteredInterval);
    };
  }, [filter]);

  useEffect(() => {
    // If company changes, change active company in initialFilter. Also reset paginators
    setFilter((prevValue) => {
      return {
        ...prevValue,
        activeCompanyId: currentCompany && currentCompany.companyId,
        pageNumber: 1,
      };
    });
  }, [currentCompany]);

  async function asyncPostCreateOrder(order: any) {
    await postCreateOrder(order);
  }

  function resetCreateOrderModalValues() {
    //Reset values in modal for reusability
    setCreateOrderValues(getCreateOrderObject());
    setValidationErrors([]);
    if (currentOrderStep > 0) {
      setCurrentOrderStep(0);
      setSalesSteps(() => initialSteps(4));
    }
    if (currentOrderStep > 0) {
      setCurrentOrderStep(0);
      setPurchaseSteps(() => initialSteps(2));
    }
  }

  function closeCreateOrderModal() {
    setIsCreateSalesOrderModalOpen(false);
    setIsCreatePurchaseOrderModalOpen(false);
    setIsDifferentAddressInCreateOrderChecked(false);
    setDifferentAddressValidationMessage({});
    setCreateOrderValidationError([]);
    setCreateOrderAddressValidationError([]);
    setOrderTemplate([]);
    resetCreateOrderModalValues();
  }

  const onChangeCreateOrderCB = useCallback((changed: any) => {
    setCreateOrderValues({
      ...createOrderValuesRef.current,
      ...changed,
    });
  }, []);

  const createOrderFromWizardHandler = async (orderType: OrderTypeEnumValues) => {
    if (orderType === OrderTypeEnum.BUY) {
      if (!checkMissingFields()) return;
      if (!(await validateDifferentAddressInCreateOrder())) return;
    } else {
      if (!checkMissingFields()) {
        return;
      }
    }

    let order = createOrderValues;
    order.companyId = currentCompany && currentCompany.companyId;
    order.userId = user && user.id;

    // Boxtype not needed for ordertype purchase, but backend struggels unless it is set > 0
    if (order.orderType === OrderTypeEnum.BUY) order.packaging.boxtype = 1;
    const { files, images, ...orderWithoutFiles } = order as any;

    asyncPostCreateOrder(orderWithoutFiles);
    closeCreateOrderModal();
  };

  function checkMissingFields() {
    const orderType = Object.keys(OrderTypeEnum).find(
      (key) => OrderTypeEnum[key as OrderTypeEnumKeys] === createOrderValues.orderType
    );

    let stepType;
    if (orderType === "BUY") {
      stepType = Object.keys(PurchaseStepTypeEnum).find(
        (key) => PurchaseStepTypeEnum[key as PurchaseStepType] === currentOrderStep
      );
    } else if (orderType === "SELL") {
      stepType = Object.keys(SalesStepTypeEnum).find(
        (key) => SalesStepTypeEnum[key as SalesStepType] === currentOrderStep
      );
    }

    if (stepType === "ORDER_DETAILS") {
      if (orderType === "SELL") {
        const validationResult: any = validateFieldsHelper(createStep1SchemaSales(t), {
          amount: parseFloat(createOrderValues.amount),
          minimumAmount: parseFloat(createOrderValues.minimumAmount) || 0,
          price: createOrderValues.price,
          minPrice: createOrderValues.minPrice || 0,
          salesItem: createOrderValues.salesItem,
          condition: createOrderValues.condition,
          size: createOrderValues.size,
        });
        setCreateOrderValidationError(validationResult);

        if (Object.keys(validationResult).length > 0) return false;
      } else if (orderType === "BUY") {
        const validationResult: any = validateFieldsHelper(createStep1SchemaBuy(t), {
          amount: parseFloat(createOrderValues.amount),
          price: createOrderValues.price,
          maxPrice: createOrderValues.maxPrice || 0,
          salesItem: createOrderValues.salesItem,
          condition: createOrderValues.condition,
          size: createOrderValues.size,
        });
        setCreateOrderValidationError(validationResult);
        if (Object.keys(validationResult).length > 0) return false;
      }
    } else if (stepType === "IMAGE_UPLOAD") {
      const validationResult: any = validateFieldsHelper(createFileUploadSchema(t), createOrderValues);
      setCreateOrderValidationError(validationResult);

      if (Object.keys(validationResult).length > 0) return false;
    } else if (stepType === "LOCATION_DELIVERY") {
      const validationResult: any = validateFieldsHelper(createStep2Schema(t), createOrderValues);
      setCreateOrderValidationError(validationResult);

      if (Object.keys(validationResult).length > 0) return false;
    } else if (stepType === "PACKING") {
      const validationResult: any = validateFieldsHelper(createStep3Schema(t), createOrderValues.packaging);
      setCreateOrderValidationError(validationResult);

      if (Object.keys(validationResult).length) return false;
    }

    return true;
  }

  const clickBackSalesStepHandler = () => {
    setSalesSteps((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentOrderStep].isActive = false;
        prevStep[currentOrderStep].isDone = false;
        prevStep[currentOrderStep - 1].isActive = true;
        return x;
      })
    );

    setCurrentOrderStep((prev) => prev - 1);
  };

  const clickNextSalesStepHandler = async () => {
    // prevent stepping into undefined step
    if (salesSteps.length - 1 <= currentOrderStep) {
      return;
    }
    //Validate steps
    if (!checkMissingFields()) return;
    if (!(await validateDifferentAddressInCreateOrder())) return;
    setSalesSteps((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentOrderStep].isActive = false;
        prevStep[currentOrderStep].isDone = true;
        prevStep[currentOrderStep + 1].isActive = true;
        return x;
      })
    );

    setCurrentOrderStep((prev) => prev + 1);
  };

  const clickBackPurchaseStepHandler = () => {
    setPurchaseSteps((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentOrderStep].isActive = false;
        prevStep[currentOrderStep].isDone = false;
        prevStep[currentOrderStep - 1].isActive = true;
        return x;
      })
    );

    setCurrentOrderStep((prev) => prev - 1);
  };

  const clickNextPurchaseStepHandler = () => {
    // prevent stepping into undefined step
    if (purchaseSteps.length - 1 <= currentOrderStep) {
      return;
    }

    //Validate steps
    if (!checkMissingFields()) return;

    setPurchaseSteps((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentOrderStep].isActive = false;
        prevStep[currentOrderStep].isDone = true;
        prevStep[currentOrderStep + 1].isActive = true;
        return x;
      })
    );
    setCurrentOrderStep((prev) => prev + 1);
  };

  async function validateDifferentAddressInCreateOrder() {
    if (
      isDifferentAddressInCreateOrderChecked &&
      (currentOrderStep === SalesStepTypeEnum.LOCATION_DELIVERY ||
        currentOrderStep === PurchaseStepTypeEnum.LOCATION_DELIVERY)
    ) {
      const inputChecker: any = validateFieldsHelper(createStep2AddressSchema(t), {
        address: createOrderValues.orderAddress.address,
        postCode: createOrderValues.orderAddress.postCode,
        place: createOrderValues.orderAddress.place,
        countryCodeIso3: createOrderValues.orderAddress.countryCodeIso3,
      });
      setCreateOrderAddressValidationError(inputChecker);
      let transportString = "";
      let messageBarType = "";
      if (Object.keys(inputChecker).length === 0) {
        const res = await postCanTransportFrom(createOrderValues.orderAddress.postCode);
        const canTransport = res.data;
        if (canTransport.canTransportCool === false && canTransport.canTransportFish === false) {
          transportString = t("commonOrders.fromTransValidateDontTransport");
          messageBarType = MessageBar.type.Negative;
        } else if (canTransport.canTransportCool === true && canTransport.canTransportFish === true) {
          transportString = t("commonOrders.fromTransValidateDoTransport");
          messageBarType = MessageBar.type.Positive;
        } else if (canTransport.canTransportCool === true) {
          transportString = t("commonOrders.fromTransValidateCool");
          messageBarType = MessageBar.type.Positive;
        } else if (canTransport.canTransportFish === true) {
          transportString = t("commonOrders.fromTransValidateFresh");
          messageBarType = MessageBar.type.Positive;
        }
        setDifferentAddressValidationMessage({
          message: transportString,
          messageType: messageBarType,
        });
        if (canTransport.canTransportCool === true || canTransport.canTransportFish === true) {
          return true;
        } else return false;
      } else {
        setDifferentAddressValidationMessage({ message: "", messageType: "" });
        return false;
      }
    }
    return true;
  }

  function openCreateOrderModal(type: OrderTypeEnumValues) {
    if (type === OrderTypeEnum.SELL) {
      onChangeCreateOrderCB({ orderType: OrderTypeEnum.SELL });
      setIsCreateSalesOrderModalOpen(true);
    } else if (type === OrderTypeEnum.BUY) {
      onChangeCreateOrderCB({ orderType: OrderTypeEnum.BUY });
      setIsCreatePurchaseOrderModalOpen(true);
    } else if (type === OrderTypeEnum.AUCTION) {
      onChangeCreateOrderCB({ orderType: OrderTypeEnum.AUCTION });
    }
    props.setIsChooseOrderTypeOpen(false);
  }

  return (
    <>
      <Modal
        open={props.isChooseOrderTypeOpen}
        onClose={() => props.setIsChooseOrderTypeOpen(false)}
        headerText={t("commonOrders.purchaseOrSell")}
      >
        <ModalContent padded={true}>
          <Group gap="20px">
            <OrderCta
              id="create-sales-order"
              onClick={() => openCreateOrderModal(OrderTypeEnum.SELL)}
              orderType="SELL"
            />
            <OrderCta id="create-sales-order" onClick={() => openCreateOrderModal(OrderTypeEnum.BUY)} orderType="BUY" />
          </Group>
        </ModalContent>
      </Modal>
      <OrderModal
        open={isCreateSalesOrderModalOpen}
        onClose={closeCreateOrderModal}
        svgAsset={saleOrPurchase(t, createOrderValues.orderType).svgAssetWhite}
        headerText={saleOrPurchase(t, createOrderValues.orderType).tabHeaderText}
      >
        <OrderModalContent>
          <TabsWiz>
            <TabWiz isActive={salesSteps[0].isActive} isDone={salesSteps[0].isDone} stepTitle={t("common.type")}>
              <OrderDetailsSell
                onChange={onChangeCreateOrderCB}
                values={createOrderValues}
                orderTemplate={orderTemplate}
                setOrderTemplate={setOrderTemplate}
                validationErrors={validationErrors}
                fieldValidationErrors={createOrderValidationError}
                setFieldValidationErrors={setCreateOrderValidationError}
              />
            </TabWiz>
            <TabWiz isActive={salesSteps[1].isActive} isDone={salesSteps[1].isDone} stepTitle={t("common.images")}>
              <ImageUploadSell
                onChange={onChangeCreateOrderCB}
                values={createOrderValues}
                fieldValidationErrors={createOrderValidationError}
                setFieldValidationErrors={setCreateOrderValidationError}
              />
            </TabWiz>
            <TabWiz
              isActive={salesSteps[2].isActive}
              isDone={salesSteps[2].isDone}
              stepTitle={t("commonOrders.locationAndDelivery")}
              hasBorder={true}
            >
              <LocationAndDeliverySell
                onChange={onChangeCreateOrderCB}
                values={createOrderValues}
                fieldValidationErrors={createOrderValidationError}
                setFieldValidationErrors={setCreateOrderValidationError}
                fieldValidationAddressErrors={createOrderAddressValidationError}
                setFieldValidationAddressErrors={setCreateOrderAddressValidationError}
                validateDifferentAddress={validateDifferentAddressInCreateOrder}
                isDifferentAddressChecked={isDifferentAddressInCreateOrderChecked}
                setIsDifferentAddressChecked={setIsDifferentAddressInCreateOrderChecked}
                differentAddressValidationMessage={differentAddressValidationMessage}
                setDifferentAddressValidationMessage={setDifferentAddressValidationMessage}
              />
            </TabWiz>
            <TabWiz
              isActive={salesSteps[3].isActive}
              isDone={salesSteps[3].isDone}
              stepTitle={t("common.packaging")}
              hasBorder={true}
            >
              <PackingSell
                onChange={onChangeCreateOrderCB}
                values={createOrderValues}
                fieldValidationErrors={createOrderValidationError}
                setFieldValidationErrors={setCreateOrderValidationError}
              />
            </TabWiz>
          </TabsWiz>
          <OrderInformation salesItem={createOrderValues.salesItem} />
        </OrderModalContent>
        <ModalCTAs>
          <CTA id="cancel-create-sales-order" intent="tertiary" onClick={() => closeCreateOrderModal()}>
            {t("common.cancel")}
          </CTA>
          {currentOrderStep > 0 && (
            <CTA id="back-create-sales-order" intent="primary" size="lg" onClick={clickBackSalesStepHandler}>
              {t("common.back")}
            </CTA>
          )}
          <CTA
            id="next-create-sales-order"
            intent="primary"
            size="lg"
            onClick={
              currentOrderStep !== SalesStepTypeEnum.PACKING
                ? () => clickNextSalesStepHandler()
                : () => createOrderFromWizardHandler(createOrderValues.orderType)
            }
          >
            {currentOrderStep !== SalesStepTypeEnum.PACKING ? t("common.next") : t("commonOrders.createOrder")}
          </CTA>
        </ModalCTAs>
      </OrderModal>

      <OrderModal
        open={isCreatePurchaseOrderModalOpen}
        onClose={() => closeCreateOrderModal()}
        svgAsset={saleOrPurchase(t, createOrderValues.orderType).svgAssetWhite}
        headerText={saleOrPurchase(t, createOrderValues.orderType).tabHeaderText}
      >
        <OrderModalContent>
          <TabsWiz>
            <TabWiz isActive={purchaseSteps[0].isActive} isDone={purchaseSteps[0].isDone} stepTitle={t("common.type")}>
              <OrderDetailsBuy
                onChange={onChangeCreateOrderCB}
                values={createOrderValues}
                orderTemplate={orderTemplate}
                setOrderTemplate={setOrderTemplate}
                validationErrors={validationErrors}
                fieldValidationErrors={createOrderValidationError}
                setFieldValidationErrors={setCreateOrderValidationError}
              />
            </TabWiz>
            <TabWiz
              isActive={purchaseSteps[1].isActive}
              isDone={purchaseSteps[1].isDone}
              stepTitle={t("commonOrders.locationAndDelivery")}
              hasBorder={true}
            >
              <LocationAndDeliveryBuy
                onChange={onChangeCreateOrderCB}
                values={createOrderValues}
                fieldValidationErrors={createOrderValidationError}
                setFieldValidationErrors={setCreateOrderValidationError}
                fieldValidationAddressErrors={createOrderAddressValidationError}
                setFieldValidationAddressErrors={setCreateOrderAddressValidationError}
                validateDifferentAddress={validateDifferentAddressInCreateOrder}
                isDifferentAddressChecked={isDifferentAddressInCreateOrderChecked}
                setIsDifferentAddressChecked={setIsDifferentAddressInCreateOrderChecked}
                differentAddressValidationMessage={differentAddressValidationMessage}
                setDifferentAddressValidationMessage={setDifferentAddressValidationMessage}
              />
            </TabWiz>
          </TabsWiz>
          <OrderInformation salesItem={createOrderValues.salesItem} />
        </OrderModalContent>
        <ModalCTAs>
          <CTA id="cancel-create-purchase-order" intent="tertiary" onClick={() => closeCreateOrderModal()}>
            {t("common.cancel")}
          </CTA>
          {currentOrderStep > 0 && (
            <CTA id="back-create-purchase-order" intent="primary" size="lg" onClick={clickBackPurchaseStepHandler}>
              {t("common.back")}
            </CTA>
          )}
          <CTA
            id="next-create-purchase-order"
            intent="primary"
            size="lg"
            onClick={
              currentOrderStep !== PurchaseStepTypeEnum.LOCATION_DELIVERY
                ? () => clickNextPurchaseStepHandler()
                : () => createOrderFromWizardHandler(createOrderValues.orderType)
            }
          >
            {currentOrderStep !== PurchaseStepTypeEnum.LOCATION_DELIVERY
              ? t("common.next")
              : t("commonOrders.createOrder")}
          </CTA>
        </ModalCTAs>
      </OrderModal>
    </>
  );
}
