import React, { useEffect, useState, useRef } from "react";
import SVGAsset from "../SVGAsset/SVGAsset";

import "./ContextPanel.css";

interface Props {
  options: any[];
}

export default function ContextPanel(props: Props) {
  const { options } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handler = (e: any) => {
      let parent = e.target;
      while ((parent = parent.parentElement) !== null) {
        if (parent === ref.current) {
          break;
        }
      }
      const isTargetComponent = parent !== null;

      if (!isTargetComponent) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", handler);

    const cleanup = () => {
      document.body.removeEventListener("click", handler);
    };

    return cleanup;
  }, [setOpen, ref]);

  function openContext() {
    setOpen(true);
  }

  return (
    <>
      <div ref={ref} className="context-panel">
        <SVGAsset onClick={() => openContext()} name="dotdotdot-grey" className="open-context-panel" />
        {!!open && (
          <>
            <div className="options">
              {options.map((option, i) => {
                return (
                  <div
                    className="option"
                    onClick={() => {
                      option.action();
                      setOpen(false);
                    }}
                    key={"context-option-" + i}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}
