import React from "react";
import Textfield from "../TextField/TextField";
import Dropdown from "../Dropdown/Dropdown";
import PropTypes from "prop-types";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { customDropdownStyles } from "../Dropdown/Dropdown";

import "./TextfieldDropdown.css";

export default function TextfieldDropdown(props) {
  const {
    id,
    textfieldLabel,
    textfieldPlaceholder,
    textfieldValue,
    textfieldOnChange,
    textfieldDisabled,
    textFieldType,
    textFieldOnBlur,
    textFieldName,
    dropdownLabel,
    dropdownOptions,
    dropdownValue,
    dropdownDisabled,
    dropdownOnChange,
    dropdownIsClearable,
    dropdownClearValue,
    menuPlacement,
    errormessage,
  } = props;

  return (
    <div id={id} className="flex col txtdropdown-area">
      <div className="txtdropdown">
        <Textfield
          id={`id-${"textfield"}`}
          name={textFieldName}
          label={textfieldLabel}
          disabled={textfieldDisabled}
          type={textFieldType}
          placeholder={textfieldPlaceholder}
          value={textfieldValue}
          onChange={textfieldOnChange}
          onBlur={textFieldOnBlur}
        />
        <Dropdown
          id={`id-${"dropdown"}`}
          overrideStyle={customDropdownStyles}
          label={dropdownLabel}
          isDisabled={dropdownDisabled}
          options={dropdownOptions}
          value={dropdownValue}
          onChange={dropdownOnChange}
          isClearable={dropdownIsClearable}
          clearValue={dropdownClearValue}
          menuPlacement={menuPlacement}
        />
      </div>
      <ErrorMessage errormessage={errormessage} />
    </div>
  );
}

TextfieldDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  textfieldLabel: PropTypes.string,
  textfieldPlaceholder: PropTypes.string,
  textfieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textfieldOnChange: PropTypes.func,
  textfieldDisabled: PropTypes.bool,
  textFieldType: PropTypes.string,
  textFieldOnBlur: PropTypes.func,
  textFieldName: PropTypes.string,

  dropdownLabel: PropTypes.string,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownValue: PropTypes.any,
  dropdownDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dropdownOnChange: PropTypes.func,
  dropdownIsClearable: PropTypes.bool,
  dropdownClearValue: PropTypes.func,

  errormessage: PropTypes.string,
};
