import React, { FC, useEffect, useContext, useState } from "react";
import { getOpenTasks } from "../../../../services/Tasks";
import { getOrder, getOrderByTransactionId } from "../../../../services/Orders";
import { useTranslation } from "react-i18next";

import UserContext from "../../../../contexts/UserContext";
import Moment from "react-moment";
import Drawer from "../../../Drawer/Drawer";
import PaymentTask from "./Drawer/PaymentTask";
import TaskTypeEnum from "../../../../constants/enums/TaskTypeEnum";
import Spinner from "../../../Spinner/Spinner";
import GeneralTaskInfo from "./Drawer/GeneralTaskInfo";
import FinalAmountTask from "./Drawer/FinalAmountTask";
import TransportTask from "./Drawer/TransportTask";
import FeedbackTask from "./Drawer/FeedbackTask";
import moment from "moment-timezone";
import { User, Order, Task } from "types";

import styles from "./styles.module.css";

export default function Tasks() {
  const userContext = useContext(UserContext);
  const currentDate = moment().format();
  const { currentCompany, user } = userContext;
  const [openTasks, setOpenTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [taskInDrawer, setTaskInDrawer] = useState<Task>();
  const [orderInDrawer, setOrderInDrawer] = useState<Order>();

  const sortTasksByDate = openTasks.sort(function (a, b) {
    return new Date((a as any).dueDate).getTime() - new Date((b as any).dueDate).getTime();
  });

  useEffect(() => {
    async function loadOpenTasks() {
      setIsLoading(true);
      const result = await getOpenTasks(currentCompany.companyId);
      setOpenTasks(result.data);
    }
    loadOpenTasks();
    setIsLoading(false);
  }, [currentCompany.companyId]);

  async function openDrawer(task: Task) {
    setIsDrawerLoading(true);
    setTaskInDrawer(task);
    setIsDrawerOpen(true);
    // TODO: Implement error handling
    if (
      task.type === TaskTypeEnum.AUCTION_END_PAYMENT_STR ||
      task.type === TaskTypeEnum.FINAL_AMOUNT_STR ||
      task.type === TaskTypeEnum.AUCTION_END_TRANSPORT_STR
    ) {
      const result = await getOrder(task.connected);
      const order = result.data;
      setOrderInDrawer(order);
    } else if (task.type === TaskTypeEnum.FEEDBACK_STR) {
      const result = await getOrderByTransactionId(task.connected, currentCompany.companyId);
      const order = result.data;
      setOrderInDrawer(order);
    }
    setIsDrawerLoading(false);
  }

  async function completeTaskCB() {
    setIsLoading(true);

    const result = await getOpenTasks(currentCompany.companyId);
    setOpenTasks(result.data);
    setIsDrawerOpen(false);
    setIsLoading(false);
  }

  return (
    <div className={styles.tasksRoot}>
      <Spinner loading={isLoading} />
      <div>
        <table className="compact">
          <TaskTableHeaders />
          <tbody>
            {sortTasksByDate.map((task: Task, index: number) => {
              const isOverdue = moment(moment(task.dueDate)).isBefore(currentDate);

              return (
                <TaskRow
                  task={task}
                  index={index}
                  currentCompany={currentCompany}
                  onClick={() => openDrawer(task)}
                  className={`${styles.taskRow} ${isOverdue ? styles.overdue : ""}`}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className={styles.taskDrawerRoot}>
          <Spinner loading={isDrawerLoading} />
          {!isDrawerLoading && taskInDrawer && orderInDrawer && (
            <>
              {taskInDrawer.type === TaskTypeEnum.AUCTION_END_PAYMENT_STR && (
                <PaymentTask
                  orderId={taskInDrawer.connected}
                  taskId={taskInDrawer.id}
                  onCloseCB={() => completeTaskCB()}
                />
              )}
              {taskInDrawer.type === TaskTypeEnum.AUCTION_END_TRANSPORT_STR && (
                <TransportTask
                  orderId={taskInDrawer.connected}
                  taskId={taskInDrawer.id}
                  currentCompany={currentCompany}
                  onCloseCB={() => completeTaskCB()}
                />
              )}
              {taskInDrawer.type === TaskTypeEnum.FINAL_AMOUNT_STR && (
                <FinalAmountTask
                  amount={orderInDrawer.amount}
                  amountDeviation={orderInDrawer.amountDeviation}
                  orderId={taskInDrawer.connected}
                  taskId={taskInDrawer.id}
                  onCloseCB={() => completeTaskCB()}
                />
              )}
              {taskInDrawer.type === TaskTypeEnum.FEEDBACK_STR && (
                <FeedbackTask
                  taskId={taskInDrawer.id}
                  transactionId={taskInDrawer.connected}
                  orderId={orderInDrawer.id}
                  senderUserId={user.id}
                  onCloseCB={() => completeTaskCB()}
                />
              )}
              <GeneralTaskInfo
                order={orderInDrawer}
                currentCompany={currentCompany}
                taskType={taskInDrawer.type}
                taskCounterParty={taskInDrawer.counterParty}
                taskPrice={taskInDrawer.price}
                taskAmount={taskInDrawer.amount}
              />
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}

interface TaskRowProps {
  task: Task;
  index: number;
  className: string;
  onClick: () => void;
  currentCompany: User;
}

const TaskRow: FC<TaskRowProps> = ({ index, task, currentCompany, ...props }) => {
  const { t } = useTranslation();

  return (
    <tr id={`opentask-row-${index}`} key={`opentask-row-${index}`} className={props.className} onClick={props.onClick}>
      <td data-label={t("common.dueDate")}>
        <Moment format="Do MMMM YYYY, HH:mm">{task.dueDate}</Moment>
      </td>
      <td data-label={t("tasks.type")}>{t("common.status." + task.type)}</td>
      <td data-label={t("tasks.status")}>{task.status}</td>
      <td data-label={t("tasks.counterParty")}>{task.counterParty}</td>
      <td data-label={t("common.type")}>{task.salesItem ? t("common.plastic." + task.salesItem) : " "}</td>
      <td data-label={t("common.amount")}>{task.amount ? task.amount + " kg" : " "}</td>
      <td data-label={t("common.price")}>
        {task.price ? task.price + " " + currentCompany.companyCurrency + " per kg" : " "}
      </td>
    </tr>
  );
};

const TaskTableHeaders: FC = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <th scope="col">{t("common.dueDate")}</th>
        <th scope="col">{t("tasks.type")}</th>
        <th scope="col">{t("tasks.status")}</th>
        <th scope="col">{t("tasks.counterParty")}</th>
        <th scope="col">{t("common.type")}</th>
        <th scope="col">{t("common.amount")}</th>
        <th scope="col">{t("common.price")}</th>
      </tr>
    </thead>
  );
};
