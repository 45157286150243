import React, { useRef, useEffect, useState } from "react";
import { useIsMount } from "../../hooks/useIsMount";

function getHeight(ref) {
  if (ref && ref.current && ref.current.scrollHeight) {
    return `${ref.current.scrollHeight}px`;
  }
  return "0px";
}

function FilterContent(props) {
  const { open } = props;
  const [height, setHeight] = useState("0");
  const isInitialized = useIsMount();
  const ref = useRef(null);

  function onTransitionEnd(e) {
    if (e.target === ref.current && e.propertyName === "height") {
      if (open) {
        setHeight("auto");
      }
    }
  }

  useEffect(() => {
    if (open) {
      if (isInitialized) {
        setHeight("auto");
      } else {
        setHeight(getHeight(ref));
      }
    } else if (!isInitialized) {
      setHeight(getHeight(ref));
      window.requestAnimationFrame(() => {
        setHeight("0");
      });
    }
  }, [open, isInitialized]);

  return (
    <div className="blinds" ref={ref} style={{ height }} onTransitionEnd={onTransitionEnd}>
      {props.children}
    </div>
  );
}

export default FilterContent;
