import React from "react";
import StepLabel from "../../_Reviver/atoms/StepLabel";

import "./Step.css";

function step(props) {
  let status = "";
  if (props.isActive) {
    status = "active";
  } else if (props.isDone) {
    status = "done";
  } else if (props.isActive === false) {
    status = "notActive";
  }

  return (
    <div className={"step " + status}>
      <StepLabel label={props.stepTitle} titleStatus={status} />
      <div className="body">
        {props.isActive && (
          <>
            <div id="scroll-ref"></div>
            {props.children}
          </>
        )}
      </div>
    </div>
  );
}

export default step;
