import React from "react";
import "./PrivacyPolicy.css";

export default function PrivacyDeclaration() {
  return (
    <section className="font-family-sans privacy-main">
      <h1>Personvernerklæring</h1>
      <p>reviver.no er en markedsplass for handel med sjømat mellom bedrifter.</p>
      <p>
        Gjennom våre løsninger samler vi inn opplysninger om bedrifter og brukere knyttet til disse. Vi er opptatte av å
        ivareta våre brukeres personvern.
      </p>
      <p>
        Denne siden beskriver vår bruk av anonyme data, personopplysninger og informasjonskapsler, hvordan vi bruker
        disse dataene og hvem vi deler dem med.
      </p>
      <h2>1. Personopplysninger</h2>
      <p>
        Personopplysninger er opplysninger og vurderinger som kan knyttes til en identifiserbar enkeltperson. Dette kan
        for eksempel være navn, telefonnummer, e-postadresse eller IP-adresse.
      </p>
      <p>
        Behandling av personopplysninger er underlagt Personopplysningsloven, og reviver.no er behandlingsansvarlig for
        personopplysninger som behandles i forbindelse med bruk av våre tjenester og produkter, og personopplysninger vi
        samler inn og bearbeider for de formål som fremgår nedenfor.
      </p>

      <h2>2. Hvilket opplysninger samler vi inn?</h2>
      <p>
        Vi samler inn opplysninger som du gir til oss og om hvordan tjenestene våre brukes. I enkelte tilfeller mottar
        vi også opplysninger fra tredjeparter.
      </p>
      <h3>2.1 Opplysninger du selv gir til oss</h3>
      <p>
        Når du registrerer deg som bruker hos oss (avgir personopplysninger i skjema eller i epost) oppgir du en del
        data som vi lagrer hos oss. Disse opplysningene lagres i vår database for å yte eller sikre leveranse av den
        tjenesten du har bestilt, samt for å forbedre tjenestene våre og for å forebygge svindel. Vi lagrer også
        opplysninger når du kontakter oss, eksempelvis ved å sende oss en e-post service eller mail, slik at vi kan
        svare på dine spørsmål.
      </p>
      <h3>2.2 Opplysninger som samles inn gjennom bruk av tjenestene våre</h3>
      <p>
        Når du bruker våre tjenester registrerer vi informasjon om hvilke tjenester du bruker og hvordan du bruker dem.
        Vi gjør dette for å forbedre tjenestene og for å tilpasse innhold til deg. Vi samler for eksempel inn
        informasjon om hvordan du besøker våre nettsteder og hvilke andre nettsteder du benytter.
        <br />
        <br />
        Informasjonen vi samler inn kan deles inn i følgende typer:
      </p>
      <p>
        2.2.1. Informasjon om din enhet og nettverk
        <br />
        Vi registrerer informasjon som brukernes enheter avgir og enhetenes tilkobling til våre tjenester. Eksempler på
        slik informasjon er type enhet, operativsystem, nettleserinformasjon, IP-adresse, informasjonskapsler (cookies)
        og unike identifikasjonsfiler. Vi tilpasser visningen av våre tjenester til den enheten du bruker. Når du kommer
        til oss via en mobiltelefon gir vi deg de tjenestene vi har som kan vises på denne typen enhet.
      </p>
      <p>
        2.2.2. Informasjon om bruk av tjenestene
        <br />
        Når du besøker reviver.no registreres din aktivitet automatisk i våre måleverktøy. Vi bruker denne informasjonen
        blant annet til å forbedre tjenestene våre, og for å gi deg innhold og markedskommunikasjon som er tilpasset
        ditt bruksmønster. Vi samler inn historikk for å se hvilke av våre sider som blir mest lest og hvilke søk etter
        sider som ikke gir tilgang til godt innhold. Ut fra denne historikken kan vi presentere bedre og mer tilpasset
        innhold til deg.
      </p>
      <p>
        2.2.3. Informasjonskapsler, lagring lokalt
        <br />
        Når du bruker reviver.no lagres informasjonskapsler og annen data lokalt på din enhet. Disse
        informasjonskapslene lagres i nettleseren du bruker – dette er tekstfiler som våre systemer leser. Når vi lagrer
        disse dataene er det for at vi ønsker å forenkle bruken av våre tjenester og for lettere kunne gi deg relevant
        informasjon når du besøker oss. Informasjonskapsler og annen lokal lagring brukes for å huske innstillinger og
        tilpasninger du har gjort. Informasjonskapsler brukes også for å måle trafikken til våre nettsteder.
      </p>
      <p>
        2.2.4. Informasjon om din brukerkonto og kundeforhold
        <br />
        Vi lagrer informasjon om brukerkontoen din, dine preferanser og registrering i vår database dersom du er
        registrert bruker. Dette er opplysninger som vi lagrer for å kunne administrere forholdet mellom deg og oss.
      </p>

      <h2>3. Hva bruker vi opplysningene til?</h2>
      <p>
        Vi arbeider hele tiden med å gi deg den best mulige tjenesten og få en god opplevelse i møte med oss. Vi vil kun
        bruke opplysningene dine for å:
      </p>
      <h3>3.1. Levere avtalte tejenester til deg</h3>
      <p>
        Personopplysninger brukes sammen med andre data for å levere tjenester vi har avtalt med deg. Vi bruker disse
        dataene for å sikre brukeropplevelsen gjennom tilpassede visninger basert på tidligere besøk og typen utstyr du
        besøker oss med.
      </p>
      <h3>3.2. Generere statistikk</h3>
      <p>Vi utarbeider statistikk og kartlegger trender for å videreutvikle og forbedre våre nettsteder.</p>
      <h3>3.3. Utarbeide personaliserte tjenester</h3>
      <p>
        For å kunne gi deg skreddersydd innhold og tilpasninger til tjenesten bruker vi data om hvordan du bruker våre
        tjenester til å tilpasse visningene ved senere besøk, og kan gi deg anbefalinger om andre tjenester eller
        innhold basert på dette.
      </p>
      <h3>3.4. Bergrense tilgang til våre systemer</h3>
      <p>
        Vi bruker opplysninger vi har om våre brukere for å begrense tilgangen til våre systemer og for å sjekke om
        disse er blitt misbrukt. Falske profiler, spamming og forsøk på å logge seg inn på andres konto hos oss.
      </p>
      <h3>3.5. Dele vårt innhold</h3>
      <p>
        På våre nettsider vil du finne funksjoner for å dele innholdet med andre. Til dette bruker vi opplysninger på
        din enhet for å benytte oss av delingsfunksjonene i Twitter og Facebook. I tillegg benytter vi oss av et system
        for å dele innhold via epost.
      </p>

      <h2>4. Hvem deler vi opplysningene med?</h2>
      <p>
        Opplysningene vi har om deg vil kunne deles med andre der det er relevant for formålene som er beskrevet i denne
        erklæringen. Vi vil ikke selge eller formidle personopplysninger på noen annen måte enn som her beskrevet, med
        mindre det er særskilt avtalt med deg.
      </p>
      <p>
        I noen tilfeller kan vi dele upersonlige data (brukerdata) med samarbeidende annonsører for å formidle målrettet
        innhold. Direkte identifiserte data (navn, telefonnummer, osv) vil vi ikke dele uten ditt eksplisitte samtykke.
      </p>

      <h2>5. Hvordan se, rette eller slette opplysningene vi har om deg?</h2>
      <p>Vi ønsker mest mulig oppdaterte opplysninger om deg.</p>
      <p>
        Vi vil ikke lagre dine persondata lenger enn det er nødvendig for å oppfylle formålet med behandlingen og våre
        lovpålagte forpliktelser.
      </p>
      <p>
        Hvis du finner feil eller mener vi sitter på opplysninger om deg som er feil kan du få disse endret eller endre
        dem selv slik:
      </p>
      <h3>5.1. Registrering i reviver.no</h3>
      <p>
        Finner du at våre opplysninger ikke stemmer kan de fleste av disse endres ved innlogging til «Min Side» på
        reviver.no – noen av disse opplysningene kan kun endres av oss og hvis du oppdager feil i disse ber vi deg
        kontakte oss på info@reviver.no for å få disse endret.
      </p>

      <h2>6. Endringer</h2>
      <p>
        Vi vil oppdatere denne personvernerklæringen for å gjenspeile endringer på våre nettsteder eller vår policy. Vi
        vil informere om de tjenestene som berøres særskilt.
      </p>
      <p>
        Denne personvernerklæringen er oppdatert sist: Mai 2022 Hvis du ønsker å komme i kontakt med oss om denne
        erklæringen eller om personvernopplysninger vi måtte ha, ta kontakt med oss på info@reviver.no.
      </p>
    </section>
  );
}
