import { FC } from "react";
import Grid from "@/components/Grid/Grid";
import { useTranslation } from "react-i18next";
import Dropdown from "@/components/Dropdown/Dropdown";
import TextField from "@/components/TextField/TextField";
import Divider from "@/components/_Reviver/atoms/Divider/";

interface Props {
  nrOfBoxesDrawer: any;
  boxtypeOptionsDrawer: any;
  selectedBoxtypeDrawer: any;
  onChangeSelectedBoxtypeDrawer: any;
  onChangeNrOfBoxesDrawer: any;
}

const OrderDetailsBuy: FC<Props> = (props) => {
  const {
    nrOfBoxesDrawer,
    boxtypeOptionsDrawer,
    selectedBoxtypeDrawer,
    onChangeSelectedBoxtypeDrawer,
    onChangeNrOfBoxesDrawer,
  } = props;

  const { t } = useTranslation();

  return (
    <Grid compact={true}>
      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="drawer-boxtype"
            label={t("common.boxtype")}
            menuPlacement={Dropdown.menuPlacement.Top}
            options={boxtypeOptionsDrawer}
            value={selectedBoxtypeDrawer}
            onChange={(e: any) => onChangeSelectedBoxtypeDrawer(e)}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextField
            id="drawer-boxamount"
            label={t("common.noofboxes")}
            type="number"
            value={nrOfBoxesDrawer}
            onChange={(e: any) => onChangeNrOfBoxesDrawer(e.target.value)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
export default OrderDetailsBuy;
