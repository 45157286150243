import { APIBASE_REVIVERAUTHENTICATION, axios, refreshTokenAxios } from "./ApiConfig";
import { getToken } from "../hooks/useToken";
import { getUserContextIds } from "../hooks/useUserContext";

let isRefreshing = false;
let requestQueue = [];

export const setupRefreshTokenInterceptor = (clearSession) => {
  axios.interceptors.response.use(
    (res) => res,
    async (error) => {
      const originalConfig = error.config;
      const token = getToken();
      const ids = getUserContextIds();
      if (!originalConfig.url.includes("login") && error.response && error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshTokenAxios
            .post(`${APIBASE_REVIVERAUTHENTICATION}api/authentication/refresh/user/${ids.userId}`, {
              headers: {
                Authorization: token,
              },
            })
            .then(
              (response) => {
                const newToken = response.headers.authorization;
                sessionStorage.setItem("token", JSON.stringify(newToken));
                isRefreshing = false;
                requestQueue = requestQueue.filter((callback) => callback(newToken));
              },
              () => {
                isRefreshing = false;
                console.log("Clearing session due to 401 Unauthorized");
                clearSession();
                window.location = window.location.origin;
              }
            );
        }
        return new Promise((resolve) => {
          requestQueue.push((token) => {
            originalConfig.headers.Authorization = token;
            return resolve(axios(originalConfig));
          });
        });
      }
      return Promise.reject(error);
    }
  );
};
