import Text from "../Text";
import styles from "./styles.module.css";

export type StepLabelTitleStatus = "active" | "done" | "notActive";

export interface StepLabelProps {
  titleStatus?: StepLabelTitleStatus;
  notStep?: boolean;
  label: string;
}

function StepLabel(props: StepLabelProps) {
  const { titleStatus, label, notStep } = props;
  const titleClass = titleStatus ? styles[titleStatus] : "";

  return (
    <div className={`${styles.stepLabelRoot} ${notStep ? styles.notStep : ""} ${titleClass}`}>
      {!notStep && <span className={`${styles.statusIndicator} ${titleClass}`}></span>}
      <label className={`${styles.label} ${titleClass}`}>
        <Text span={true} strong={true} size="sm">
          {label}
        </Text>
      </label>
    </div>
  );
}

export default StepLabel;
