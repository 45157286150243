import React, { FC, useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postHandleTransport, postCalculateTransportPrices } from "../../../../../services/Orders";
import { getCompanyById } from "../../../../../services/Company";
import { getCountries } from "../../../../../services/RegisterCompany";
import { ErrorDialogContext } from "../../../../../contexts/ErrorDialogContext";
import { CSSTransition } from "react-transition-group";

import Dropdown from "../../../../Dropdown/Dropdown";
import SVGAsset from "../../../../SVGAsset/SVGAsset";
import Spinner from "../../../../Spinner/Spinner";
import Checkbox from "../../../../Checkbox/Checkbox";
import Grid from "../../../../Grid/Grid";
import Textfield from "../../../../TextField/TextField";
import MessageBar from "../../../../MessageBar/MessageBar";

import { Transport, User, CurrencyCode, Country, TransportCompany } from "types";
import styles from "./../styles.module.css";
import Group from "@/components/_Reviver/atoms/Group";
import CTA from "@/components/_Reviver/atoms/CTA";

interface Props {
  orderId: number;
  taskId: number;
  currentCompany: User;
  onCloseCB: () => void;
}

const TransportTask: FC<Props> = ({ orderId, taskId, currentCompany, onCloseCB }) => {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};

  const [transportCompanies, setTransportCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransportCompany, setSelectedTransportCompany] = useState<TransportCompany>();
  //Diff address
  const [isDiffAddressChecked, setIsDiffAddressChecked] = useState(false);
  const diffAddressNodeRef = useRef(null);
  const [address, setAddress] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [postCode, setPostCode] = useState("");
  const [place, setPlace] = useState("");
  const [isDeliveryErrorVisible, setIsDeliveryErrorVisible] = useState(false);

  // Init load transport companies
  useEffect(() => {
    async function loadTransportCompanies() {
      const companyResult = await getCompanyById(currentCompany.companyId);
      const currentCompanyFull = companyResult.data;
      const shippingAddress = currentCompanyFull.companyAddress.find((x: any) => x.addressType === "SHIPPING");

      const result = await postCalculateTransportPrices(
        orderId,
        shippingAddress.address,
        shippingAddress.postCode,
        shippingAddress.place,
        shippingAddress.country.iso3,
        shippingAddress.country.iso2,
        shippingAddress.country.isEu
      );

      const _transportOptions = result.data.map((transport: Transport) => ({
        value: transport.companyId,
        label: `${transport.companyName} - ${transport.price[currentCompany.companyCurrency as CurrencyCode].toFixed(2)} ${currentCompany.companyCurrency}, per kg - ${transport.delivery.transportNumberOfDays}
        ${t("commonOrders.days")}`,
      }));
      setTransportCompanies(_transportOptions);
      if (_transportOptions.length > 0) {
        setIsDeliveryErrorVisible(false);
      } else {
        setIsDeliveryErrorVisible(true);
      }
    }
    loadTransportCompanies();
  }, [currentCompany, t, orderId]);

  // Init load countries
  useEffect(() => {
    async function loadCountries() {
      const result = await getCountries();

      const _countryOptions = result.data.map((country: Country) => ({
        value: country.iso2,
        iso3: country.iso3,
        iso2: country.iso2,
        isEu: country.isEu,
        label: country.name,
      }));
      setCountryOptions(_countryOptions);
    }

    loadCountries();
  }, []);

  async function submitHandler() {
    if (isDiffAddressChecked) {
      const valid = await validateDelivery();
      if (!valid) return;
    }

    setIsLoading(true);

    try {
      await postHandleTransport(orderId, [selectedTransportCompany?.value], taskId);
      setIsLoading(false);
      onCloseCB();
    } catch (error) {
      setErrorDialogOptions({
        message: "Saving transport choice failed",
        error: error as Error,
      });
      showErrorDialog(true);
    }
  }

  async function validateDelivery() {
    const result =
      selectedCountry &&
      (await postCalculateTransportPrices(
        orderId,
        address,
        postCode,
        place,
        selectedCountry.iso3,
        selectedCountry.iso2,
        selectedCountry.isEu
      ));

    const _transportOptions = result?.data.map((transport: Transport) => ({
      value: transport.companyId,
      label: `${transport.companyName} - ${transport.price[currentCompany.companyCurrency as CurrencyCode].toFixed(2)} ${currentCompany.companyCurrency}, per kg - ${transport.delivery.transportNumberOfDays}
        ${t("commonOrders.days")}`,
    }));
    setTransportCompanies(_transportOptions);

    setSelectedTransportCompany(undefined);

    if (_transportOptions.length > 0) {
      setIsDeliveryErrorVisible(false);
      return true;
    } else {
      setIsDeliveryErrorVisible(true);
      return false;
    }
  }

  return (
    <div className={styles.transportTask}>
      <Group gap="10px">
        <SVGAsset name="transport" />
        <label style={{ alignSelf: "flex-end" }}>{t("tasks.transportSelect")}</label>
      </Group>
      <Group gap="10px" align="center">
        <Dropdown
          id="transportDropdown"
          options={transportCompanies}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedTransportCompany(e as any)}
        />
        <CTA id="submit" disabled={Object.keys(selectedTransportCompany!).length < 1} onClick={() => submitHandler()}>
          {t("tasks.submit")}
        </CTA>
        <Spinner loading={isLoading} inline={true} />
      </Group>
      {isDeliveryErrorVisible && <MessageBar type="Negative" message={t("commonOrders.deliveryNotPossible")} />}
      <div>
        <Checkbox
          label={t("commonOrders.differentAddress")}
          checked={isDiffAddressChecked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsDiffAddressChecked(!isDiffAddressChecked)}
        />
        <CSSTransition
          in={isDiffAddressChecked}
          timeout={{
            enter: 1000,
            exit: 300,
          }}
          mountOnEnter
          unmountOnExit
          nodeRef={diffAddressNodeRef}
        >
          <div ref={diffAddressNodeRef}>
            <Grid>
              <Grid.Col span={6}>
                <Textfield
                  id="address-drawer"
                  label={t("commonOrders.orderAddress")}
                  value={address}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Dropdown
                  id="country-drawer"
                  label={t("common.country")}
                  options={countryOptions}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedCountry(e as any)}
                  value={selectedCountry}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Textfield
                  id="postCode-drawer"
                  label={t("commonOrders.postCode")}
                  value={postCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostCode(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Textfield
                  id="place-drawer"
                  label={t("commonOrders.place")}
                  value={place}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlace(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CTA
                  id="checkAddress"
                  disabled={!selectedCountry || !postCode || !place}
                  onClick={() => validateDelivery()}
                >
                  {t("commonOrders.calculatePrice")}
                </CTA>
              </Grid.Col>
            </Grid>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default TransportTask;
