import React from "react";
import { createRoot } from "react-dom/client";
import "./_variables.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { HeadlessMantineProvider } from "@mantine/core";
import ErrorDialog from "./containers/ErrorDialog/ErrorDialog";
import { Provider as ErrorDialogContextProvider } from "./contexts/ErrorDialogContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <BrowserRouter>
          <HeadlessMantineProvider>
            <ErrorDialogContextProvider>
              <App />
              <ErrorDialog />
            </ErrorDialogContextProvider>
          </HeadlessMantineProvider>
        </BrowserRouter>
      </HelmetProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
