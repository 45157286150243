import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import {
  startPartialCompany,
  getPartialCompany,
  updatePartialCompany,
  getCountries,
  getCompanyTypes,
  getLanguages,
  getTimeZones,
  getAddressTypes,
  completePartialCompany,
  getMembershipTypes,
} from "../../services/RegisterCompany";

import Grid from "../../components/Grid/Grid";
import Step from "../../components/Wizard/Step/Step";
import StepContainer from "../../components/Wizard/StepContainer/StepContainer";
import NarrowPage from "../../components/PageSizes/NarrowPage";
import UserContext from "../../contexts/UserContext";
import Step1 from "./Steps/Step1";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step6 from "./Steps/Step6";
import PartialCompany from "../../MockAPI/PartialCompany";

import "./RegisterCompanyWizard.css";

const _address = PartialCompany.partialCompany.address;
const addressShape = Array.isArray(_address) && _address.length !== 0 ? _address[0] : {};

const SCROLL_OPTIONS = {
  behavior: "smooth",
  block: "start",
};

async function callApi(func) {
  let data;
  try {
    const response = await func();
    data = response.data;
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        return null;
      } else {
        alert("Feil ved behandling av forespørsel. Feil: " + e.response.statusText);
      }
    } else if (e.request) {
      alert("Kunne ikke sende forespørsel. Sjekk nettverket ditt.");
    } else {
      alert("Kunne ikke sende forespørsel. Feil: " + e);
    }
    throw e;
  }
  return data;
}

export default function CompanyWizard() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [draft, setDraft] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [addressTypesOptions, setAddressTypesOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [companyTypesOptions, setCompanyTypesOptions] = useState([]);
  const [membershipTypesOptions, setMemberShiptTypesOptions] = useState([]);
  const [done, setDone] = useState(false);

  const transportOptions = [
    { value: false, label: t("common.yes") },
    { value: true, label: t("common.no") },
  ];

  useEffect(() => {
    let isMounted = true;

    async function asyncLoadWizard() {
      async function getExistingDraft(userId) {
        return await callApi(async () => await getPartialCompany(userId));
      }

      async function createNewDraft(userId) {
        return await callApi(async () => await startPartialCompany(userId));
      }

      async function loadCountries() {
        return await callApi(async () => await getCountries());
      }

      async function loadCompanyTypes() {
        return await callApi(async () => await getCompanyTypes());
      }

      async function loadLanguages() {
        return await callApi(async () => await getLanguages());
      }

      async function loadTimeZones() {
        return await callApi(async () => await getTimeZones());
      }

      async function loadAddressTypes() {
        return await callApi(async () => await getAddressTypes());
      }

      async function loadMembershipTypes() {
        return await callApi(async () => await getMembershipTypes());
      }

      // Check if user has an existing draft
      let draftData, countryData, companyTypesData, languageData, timeZoneData, addressTypesData, membershipTypesData;
      try {
        draftData = await getExistingDraft(user.id);
        if (!draftData) {
          draftData = await createNewDraft(user.id);
        }
        countryData = await loadCountries();
        companyTypesData = await loadCompanyTypes();
        languageData = await loadLanguages();
        timeZoneData = await loadTimeZones();
        addressTypesData = await loadAddressTypes();
        membershipTypesData = await loadMembershipTypes();
      } catch (e) {
        console.log("ERROR: ", e.response);
        return;
      }

      setDraft(draftData);
      if (draftData.submitted) {
        setStep((steps) =>
          steps.map((step) => {
            step.isActive = false;
            step.isDone = true;
            return step;
          })
        );
        setDone(true);
        scrollToContent();
      }

      // Country dropdowns
      setCountries(countryData);
      const _countryOptions = countryData.map((country) => ({
        value: country.id,
        label: country.name,
        iso3Code: country.iso3,
      }));
      setCountryOptions(_countryOptions);

      // Language dropdown
      const _languageOptions = languageData.languages.map((language) => ({
        value: language.id,
        label: language.name,
      }));
      setLanguageOptions(_languageOptions);

      // TimeZone dropdown
      const _timeZoneOptions = timeZoneData.timeZone.map((timeZone) => ({
        value: timeZone,
        label: timeZone,
      }));
      setTimeZoneOptions(_timeZoneOptions);

      // Company types
      const _companyTypesOptions = companyTypesData.companyTypes
        .filter((type) => type !== "TRANSPORT")
        .map((type) => ({
          value: type,
          label: t("registerCompany.companyTypes." + type),
        }));
      setCompanyTypesOptions(_companyTypesOptions);

      // Address types
      const _addressTypesOptions = addressTypesData.addressTypes.map((type) => ({
        value: type,
        label: t("registerCompany.addressTypes." + type),
      }));
      setAddressTypesOptions(_addressTypesOptions);

      // Membership types
      const _membershipTypesOptions = membershipTypesData.map((type) => ({
        value: type,
        label: capitalize(type),
      }));
      setMemberShiptTypesOptions(_membershipTypesOptions);
    }

    if (isMounted) {
      asyncLoadWizard();
    }

    return () => {
      isMounted = false;
    };
  }, [setDraft, setCountries, setCountryOptions, t, user]);

  const [steps, setStep] = useState(() => {
    return [
      { key: "firstStep", isActive: true, isDone: false },
      // { key: 'secondStep', isActive: false, isDone: false },
      { key: "thirdStep", isActive: false, isDone: false },
      { key: "fourthStep", isActive: false, isDone: false },
      // { key: 'fifthStep', isActive: false, isDone: false },
      { key: "sixthstep", isActive: false, isDone: false },
    ];
  });

  const [currentStep, setCurrentStep] = useState(() => {
    return 0;
  });

  function onChange(change) {
    setDraft({
      ...draft,
      partialCompany: {
        ...draft.partialCompany,
        ...change,
      },
    });
  }

  const clickNextHandler = async (step) => {
    // prevent stepping into undefined step
    if (steps.length - 1 <= currentStep) {
      return;
    }

    // Lagre
    try {
      await callApi(async () => updatePartialCompany(draft));
    } catch (e) {
      alert("Feil ved oppdatering av utkast " + e);
    }

    setStep((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentStep].isActive = false;
        prevStep[currentStep].isDone = true;
        prevStep[currentStep + 1].isActive = true;
        return x;
      })
    );

    setCurrentStep((prev) => prev + 1);
    scrollToContent();
  };

  const clickBackHandler = async () => {
    if (currentStep === 0) {
      return;
    }

    // Lagre
    try {
      await callApi(async () => updatePartialCompany(draft));
    } catch (e) {
      alert("Feil ved oppdatering av utkast " + e);
    }

    setStep((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentStep].isActive = false;
        prevStep[currentStep].isDone = false;
        prevStep[currentStep - 1].isActive = true;
        return x;
      })
    );

    setCurrentStep(currentStep - 1);
    scrollToContent();
  };

  function scrollToContent() {
    const content = document.getElementById("scroll-ref");
    if (content) {
      content.scrollIntoView(SCROLL_OPTIONS);
    }
  }

  async function submit() {
    try {
      await callApi(async () => completePartialCompany(draft));

      setStep((prevStep) =>
        prevStep.map((x) => {
          prevStep[currentStep].isActive = false;
          prevStep[currentStep].isDone = true;
          return x;
        })
      );
      setDone(true);
      scrollToContent();
    } catch (e) {
      alert("Feil ved oppdatering av utkast " + e);
      setDone(false);
    }
  }

  if (!draft) {
    return null;
  }

  return (
    <div className="register-company">
      <Helmet>
        <title>Reviver - {t("registerCompany.registerCompany")}</title>
        <meta name="description" content="Reviver - {t('registerCompany.registerCompany')" />
      </Helmet>

      <NarrowPage>
        <Grid className="heading-illustration">
          <Grid.Col span={7}>
            <p>
              {t("dashboard.welcome").toUpperCase()} <b>{user.firstname.toUpperCase()}</b>
            </p>
            <p className="font-family-sans">{t("dashboard.welcomeText")}</p>
          </Grid.Col>
          <Grid.Col span={5} className="center vcenter"></Grid.Col>
        </Grid>

        <StepContainer className="container-background">
          {/* STEP 1 */}
          <Step isActive={steps[0].isActive} isDone={steps[0].isDone} stepTitle={t("step1.title")}>
            <Step1
              draft={draft}
              onChange={onChange}
              clickNextHandler={clickNextHandler}
              countryOptions={countryOptions}
              addressTypesOptions={addressTypesOptions}
              countries={countries}
              addressShape={addressShape}
            />
          </Step>

          {/* STEP 2 fjernet midlertidig */}

          {/* STEP 3 */}
          <Step isActive={steps[1].isActive} isDone={steps[1].isDone} stepTitle={t("step3.title")}>
            <Step3
              draft={draft}
              onChange={onChange}
              clickBackHandler={clickBackHandler}
              clickNextHandler={clickNextHandler}
              languageOptions={languageOptions}
              timeZoneOptions={timeZoneOptions}
              transportOptions={transportOptions}
              companyTypesOptions={companyTypesOptions}
              membershipTypesOptions={membershipTypesOptions}
            />
          </Step>

          {/* STEP 4 */}
          <Step isActive={steps[2].isActive} isDone={steps[2].isDone} stepTitle={t("step4.title")}>
            <Step4
              draft={draft}
              setDraft={setDraft}
              clickBackHandler={clickBackHandler}
              clickNextHandler={clickNextHandler}
            />
          </Step>

          {/* STEP 5 fjernet midlertidig, potensielt permanent */}
          {/*           
          <Step 
            isActive={steps[3].isActive}
            isDone={steps[3].isDone}
            stepTitle={t("step5.title")}
          >
            <Step5 
              signers={draft.partialCompany.signers}
              teamMembers={draft.partialCompany.teamMembers}
              onChange={onChange}
              clickBackHandler={clickBackHandler}
              submit={submit}
            />
          </Step> 
          */}

          {/* STEP 6 */}
          <Step isActive={steps[3].isActive} isDone={steps[3].isDone} stepTitle={t("step6.title")}>
            <Step6
              terms={draft.partialCompany.terms}
              setDraft={setDraft}
              onChange={onChange}
              clickBackHandler={clickBackHandler}
              submit={submit}
            />
          </Step>

          {done && (
            <>
              <div className="steps-done">
                <h2>{t("registerCompany.doneHeader")}</h2>
                <p>{t("registerCompany.doneText1")}</p>
                <p>{t("registerCompany.doneText2")}</p>
              </div>
            </>
          )}
        </StepContainer>
      </NarrowPage>
    </div>
  );
}
