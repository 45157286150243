import { default as _axios } from "axios";
import { getToken } from "../hooks/useToken";
import { getUserContextIds } from "../hooks/useUserContext";

import moment from "moment-timezone";
//Altered toJSON so that Axios serializes the date correctly (regardless of timezone on 'this').
delete Date.prototype.toJSON;

// eslint-disable-next-line
Date.prototype.toJSON = function () {
  //This gets correct date and time (converted according to company timezone) but logs as wrong timezone (browser timezone, not company...)
  const dateConvertedToUTC = moment(this).utc(false).toDate();
  //This converts it back to a moment object, so that we can put on correct format
  const dd = moment(dateConvertedToUTC).local();

  return dd.format("YYYY-MM-DDTHH:mm:ss.000") + "Z";
};

const axios = _axios.create();
axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    const ids = getUserContextIds() || {};
    if (token) {
      config.headers["Authorization"] = token;
    }
    if (ids.userId) config.headers["UserId"] = ids.userId;
    if (ids.companyId) config.headers["CompanyId"] = ids.companyId;
    config.headers["SiteId"] = SITE_ID;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshTokenAxios = _axios.create();
refreshTokenAxios.interceptors.request.use(
  (config) => {
    const token = getToken();
    const ids = getUserContextIds() || {};
    config.headers["Content-Type"] = "application/json";
    if (token) {
      config.headers["Authorization"] = token;
    }
    if (ids.userId) config.headers["UserId"] = ids.userId;
    if (ids.companyId) config.headers["CompanyId"] = ids.companyId;
    config.headers["SiteId"] = SITE_ID;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const HOST_REVIVERUSERS = import.meta.env.VITE_HOST_REVIVERUSERS;
const HOST_REVIVERAPPLICATION = import.meta.env.VITE_HOST_REVIVERAPPLICATION;
const HOST_REVIVERAUTHENTICATION = import.meta.env.VITE_HOST_REVIVERAUTHENTICATION;
const HOST_REVIVERDOCUMENTARCHIVE = import.meta.env.VITE_HOST_REVIVERDOCUMENTARCHIVE;
const HOST_REVIVERAUCTION = import.meta.env.VITE_HOST_REVIVERAUCTION;
const HOST_REVIVERACCOUNTING = import.meta.env.VITE_HOST_REVIVERACCOUNTING;
const HOST_REVIVERTRANSPORT = import.meta.env.VITE_HOST_REVIVERTRANSPORT;
const HOST_REVIVERMESSAGING = import.meta.env.VITE_HOST_REVIVERMESSAGING;
const HOST_REVIVERTASKS = import.meta.env.VITE_HOST_REVIVERTASKS;
const HOST_REVIVERFEEDBACK = import.meta.env.VITE_HOST_REVIVERFEEDBACK;
const HOST_DEVELOPMENT_MOCK = import.meta.env.VITE_HOST_DEVELOPMENT_MOCK;
const SITE_ID = import.meta.env.VITE_SITE_ID;
const IS_DEV = process.env.NODE_ENV === "development";
const IS_MOCK = !!import.meta.env.VITE_MOCK;
const PROTOCOL = window.location.protocol;

let APIBASE_REVIVERUSERS;
let APIBASE_REVIVERAPPLICATION;
let APIBASE_REVIVERAUTHENTICATION;
let APIBASE_REVIVERDOCUMENTARCHIVE;
let APIBASE_REVIVERAUCTION;
let APIBASE_REVIVERACCOUNTING;
let APIBASE_REVIVERTRANSPORT;
let APIBASE_REVIVERMESSAGING;
let APIBASE_REVIVERTASKS;
let APIBASE_REVIVERFEEDBACK;
let IS_DEV_NOT_MOCK = IS_DEV && !IS_MOCK;

// console.log("API CONFIG: IS_DEV: ", IS_DEV);
// console.log("API CONFIG: IS_MOCK: ", IS_MOCK);
// console.log("API CONFIG: IS_DEV_NOT_MOCK: ", IS_DEV_NOT_MOCK);
// console.log("API CONFIG: process.env.NODE_ENV: ", process.env.NODE_ENV);

if (IS_DEV && IS_MOCK) {
  const common = `${PROTOCOL}//${HOST_DEVELOPMENT_MOCK}/`;
  APIBASE_REVIVERUSERS = common;
  APIBASE_REVIVERAPPLICATION = common;
  APIBASE_REVIVERAUTHENTICATION = common;
  APIBASE_REVIVERDOCUMENTARCHIVE = common;
  APIBASE_REVIVERAUCTION = common;
  APIBASE_REVIVERACCOUNTING = common;
  APIBASE_REVIVERTRANSPORT = common;
  APIBASE_REVIVERMESSAGING = common;
  APIBASE_REVIVERTASKS = common;
  APIBASE_REVIVERFEEDBACK = common;
} else {
  APIBASE_REVIVERUSERS = `${PROTOCOL}//${HOST_REVIVERUSERS}/`;
  APIBASE_REVIVERAPPLICATION = `${PROTOCOL}//${HOST_REVIVERAPPLICATION}/`;
  APIBASE_REVIVERAUTHENTICATION = `${PROTOCOL}//${HOST_REVIVERAUTHENTICATION}/`;
  APIBASE_REVIVERDOCUMENTARCHIVE = `${PROTOCOL}//${HOST_REVIVERDOCUMENTARCHIVE}/`;
  APIBASE_REVIVERAUCTION = `${PROTOCOL}//${HOST_REVIVERAUCTION}/`;
  APIBASE_REVIVERACCOUNTING = `${PROTOCOL}//${HOST_REVIVERACCOUNTING}/`;
  APIBASE_REVIVERTRANSPORT = `${PROTOCOL}//${HOST_REVIVERTRANSPORT}/`;
  APIBASE_REVIVERMESSAGING = `${PROTOCOL}//${HOST_REVIVERMESSAGING}/`;
  APIBASE_REVIVERTASKS = `${PROTOCOL}//${HOST_REVIVERTASKS}`;
  APIBASE_REVIVERFEEDBACK = `${PROTOCOL}//${HOST_REVIVERFEEDBACK}`;
}

export {
  APIBASE_REVIVERUSERS,
  APIBASE_REVIVERAPPLICATION,
  APIBASE_REVIVERAUTHENTICATION,
  APIBASE_REVIVERDOCUMENTARCHIVE,
  APIBASE_REVIVERAUCTION,
  APIBASE_REVIVERACCOUNTING,
  APIBASE_REVIVERTRANSPORT,
  APIBASE_REVIVERMESSAGING,
  APIBASE_REVIVERTASKS,
  APIBASE_REVIVERFEEDBACK,
  SITE_ID,
  IS_DEV_NOT_MOCK,
  IS_DEV,
  axios,
  refreshTokenAxios,
};
