import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Text from "../Text";
import styles from "./styles.module.css";

interface NavItemProps {
  href: string;
  text: string;
  isActive?: boolean;
}

const NavItem: FC<PropsWithChildren<NavItemProps>> = ({ href, text, isActive }) => {
  return (
    <li className={styles.navItemRoot}>
      <Link to={href} className={`${styles.link} ${isActive ? styles.active : ""}`}>
        <Text span={true} display="technical" tt="uppercase" strong={isActive}>
          {text}
        </Text>
      </Link>
    </li>
  );
};

export default NavItem;
