import React, { useState, useEffect, ImgHTMLAttributes } from "react";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  name: string; // Pass the file name or identifier for the SVG
}

const SVGAsset: React.FC<Props> = ({ name, ...props }) => {
  const [SvgComponent, setSvgComponent] = useState<string | null>(null);

  useEffect(() => {
    // Dynamically import the SVG based on the name
    const loadSvg = async () => {
      try {
        const Svg = await import(`../../assets/svg/${name}.svg?url`);
        setSvgComponent(() => Svg.default);
      } catch (error) {
        console.error(`Failed to load SVG: ${name}`, error);
        setSvgComponent(() => null); // You can set a fallback component here if desired
      }
    };

    loadSvg();
  }, [name]);

  if (!SvgComponent) {
    return <span>Loading...</span>; // Fallback content while the SVG is being loaded
  }

  return <img src={SvgComponent} alt={`${name} icon`} {...props} />;
};

export default SVGAsset;
