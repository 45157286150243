import React from "react";

export interface UserContextType {
  currentCompany: any;
  user: any;
}

const UserContext = React.createContext<any>({});

export default UserContext;
