import React, { useEffect, useRef, useState } from "react";
import Pikaday from "pikaday";
import TextField from "../TextField/TextField";
import moment from "moment-timezone";

import "./DatePicker.css";

export default function DatePicker(props) {
  const inputRef = useRef(null);
  const { onChange } = props;
  const [loaded, setLoaded] = useState(false);
  const [instance, setInstance] = useState();

  let formattedDate;
  if (props.value) {
    const date = new Date(props.value);
    // Check if not NaN (NaN is never equal to NaN)
    if (!Number.isNaN(date.getTime()) && moment.isDate(date)) {
      date.setHours(0, 0, 0, 0);
      formattedDate = moment(date).local().format("DD.MM.YYYY");
    } else {
      formattedDate = "";
    }
  } else {
    formattedDate = "";
  }

  function internalOnChange(e) {
    const value = e.target.value;
    if (!value) {
      props.onChange(null);
    }
  }

  useEffect(() => {
    const initClass = " initialized";

    if (!loaded && !inputRef?.current.className.includes(initClass)) {
      const pikaday = new Pikaday({
        field: inputRef.current,
        format: "DD.MM.YYYY",
        onSelect: (date) => {
          const newDate = moment(date).local().toDate();
          //console.log("dato json: ", JSON.stringify({ date: newDate }));
          onChange(newDate);
        },
        toString: (date, format) => {
          return !moment.isDate(date) ? "" : moment(date).local().format(format);
        },
      });
      setInstance(pikaday);
      setLoaded(true);
      inputRef.current.className += initClass;
    } else if (instance) {
      if (props.setmindate) instance.setMinDate(props.setmindate);
      if (props.setmaxdate) instance.setMaxDate(props.setmaxdate);
    }
  }, [props.onChange, loaded, props.setmaxdate, props.setmindate, instance, onChange]);

  return (
    <div className="date-picker">
      <TextField
        {...props}
        className="datepicker"
        ref={inputRef}
        value={formattedDate}
        onKeyUp={internalOnChange}
        readOnly
      />
    </div>
  );
}
