import React, { useContext } from "react";
import Grid from "@/components/Grid/Grid";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import UserContext from "@/contexts/UserContext";
import Pagination from "@/components/Pagination/Pagination";
import { ContractsHeader } from "@/components/_Reviver/molecules/SubHeader";

import styles from "./styles.module.css";
import CreateContractCta from "../../molecules/CreateContractCta";
import ContractCard from "../../organisms/ContractCard";

interface Props {
  setUserContext: any;
}

export default function Trade(props: Props) {
  const { t } = useTranslation();
  const { setUserContext } = props;
  const userContext = useContext(UserContext);
  const { currentCompany, companies } = userContext;

  return (
    <>
      <Helmet>
        <title>Reviver - {t("contracts.contracts")}</title>
        <meta name="description" content="Reviver - {t('contracts.contracts')}" />
      </Helmet>

      <div className={styles.contractsRoot}>
        <ContractsHeader companyName={currentCompany.companyName} totalContracts={5} sortOptions={[]} />
        <div className={styles.activeContracts}>
          <div className={styles.activeContractsInner}>
            <CreateContractCta onClick={() => {}} />
            <ContractCard contract={{}} />
            <ContractCard contract={{}} />
            <ContractCard contract={{}} />
          </div>
        </div>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12} className="flex right result-piginator">
              <Pagination numberOfPages={0} neighbours={2} onClick={() => {}} reset={!!currentCompany} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}
