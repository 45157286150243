import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyNetworkFull } from "@/services/Company";
import { getCountries } from "@/services/RegisterCompany";
import { CSSTransition } from "react-transition-group";

import Grid from "@/components/Grid/Grid";
import TextField from "@/components/TextField/TextField";
import DatePicker from "@/components/DatePicker/DatePicker";
import moment from "moment-timezone";
import MessageBar from "@/components/MessageBar/MessageBar";
import Dropdown from "@/components/Dropdown/Dropdown";
import Checkbox from "@/components/Checkbox/Checkbox";
import DropdownMulti from "@/components/DropdownMulti/DropdownMulti";
import UserContext from "@/contexts/UserContext";
import CTA from "@/components/_Reviver/atoms/CTA";

import "./OrderStep.css";

export default function LocationAndDelivery(props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [isDeliveryDatesCBChecked, setIsDeliveryDatesCBChecked] = useState(false);
  // const deliveryDatesNodeRef = React.useRef(null);
  const differentAddressNodeRef = React.useRef(null);

  const {
    onChange,
    values,
    fieldValidationErrors,
    validateDifferentAddress,
    isDifferentAddressChecked,
    setIsDifferentAddressChecked,
    differentAddressValidationMessage,
    setDifferentAddressValidationMessage,
    setFieldValidationAddressErrors,
    fieldValidationAddressErrors,
  } = props;

  useEffect(() => {
    async function asyncGetAllCompanies() {
      const availableCompanies = await getCompanyNetworkFull(currentCompany.companyId);

      const _availableCompanies = availableCompanies.data.companies.map((company) => ({
        value: company.id,
        label: company.companyName,
      }));
      setCompaniesOptions(_availableCompanies);
    }

    // Country
    async function asyncGetCountries() {
      const countries = await getCountries();

      const _countryOptions = countries.data.map((country) => ({
        value: country.id,
        label: country.name,
        iso3Code: country.iso3,
        iso2Code: country.iso2,
        isEu: country.isEu,
      }));
      setCountryOptions(_countryOptions);
    }

    asyncGetAllCompanies();
    asyncGetCountries();
  }, [currentCompany.companyId]);

  //Set country (used for going back)
  useEffect(() => {
    setSelectedCountry(countryOptions.find((item) => item.iso3Code === values.orderAddress.countryCodeIso3));
  }, [countryOptions, values.orderAddress.countryCodeIso3]);
  // Set selected target company on initialize
  useEffect(() => {
    if (values.companiesToTradeWith) {
      const _selectedCompanies = companiesOptions.filter((company) =>
        values.companiesToTradeWith.some((compId) => compId === company.value)
      );
      setSelectedCompanies(_selectedCompanies);
    }
  }, [companiesOptions, values.companiesToTradeWith]);

  function onChangeDifferentAddressCB(e) {
    setIsDifferentAddressChecked(e.checked);

    //if unchecking -> clear fields so they dont get used when creating order
    if (!e.checked) {
      onChange({
        orderAddress: {
          address: "",
          postCode: "",
          place: "",
          countryCodeIso3: "",
          countryCodeIso2: "",
          isEu: false,
        },
      });
      setFieldValidationAddressErrors({});
      setDifferentAddressValidationMessage({ message: "", messageType: "" });
    }
  }

  // function onChangeDeliveryDatesCB(e) {
  //   setIsDeliveryDatesCBChecked(e.checked);

  //   //if unchecking -> clear fields so they dont get used when creating order
  //   if (!e.checked) onChange({ deliveryStart: null, deliveryEnd: null });
  // }

  function setSelectedCountryHandler(e) {
    setSelectedCountry(e);

    onChange({
      orderAddress: { ...values.orderAddress, countryCodeIso3: e.iso3Code },
    });
  }

  function onChangeTargetCompanies(e) {
    setSelectedCompanies(e);
    const companies = e.map((company) => company.value);
    onChange({ companiesToTradeWith: companies });
  }

  function onChangeValidFrom(e, propname) {
    onChange({ validFrom: e });
  }

  function onChangeValidTo(e, propname) {
    onChange({ validTo: e });
  }

  return (
    <Grid className={`${props.className} create-order-container`}>
      <Grid.Row>
        <Grid.Col span={6}>
          <DatePicker
            id="create-order-validfrom"
            name="validFrom"
            label={t("common.validFrom")}
            value={values.validFrom}
            onChange={(e) => onChangeValidFrom(e, "validFrom")}
            errormessage={fieldValidationErrors.validFrom}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePicker
            id="create-order-validto"
            name="validTo"
            label={t("common.validTo")}
            value={values.validTo}
            onChange={(e) => onChangeValidTo(e, "validTo")}
            setmindate={moment(values.validFrom).toDate()}
            setmaxdate={moment(values.validFrom).add(14, "d").toDate()}
            errormessage={fieldValidationErrors.validTo}
          />
        </Grid.Col>
      </Grid.Row>

      {/* <Grid.Row>
        <Grid.Col span={12}>
          <Checkbox
            label={t("commonOrders.future")}
            checked={isDeliveryDatesCBChecked}
            onChange={(e) => onChangeDeliveryDatesCB(e)}
          />
        </Grid.Col>
      </Grid.Row>

      <CSSTransition
        in={isDeliveryDatesCBChecked}
        timeout={{
          enter: 1000,
          exit: 300,
        }}
        mountOnEnter
        unmountOnExit
        classNames="create-order-transitions"
        nodeRef={deliveryDatesNodeRef}
      >
        <Grid.Row>
          <Grid.Col span={12}>
            <div ref={deliveryDatesNodeRef}>
              <Grid.Row>
                <Grid.Col span={6}>
                  <DatePicker
                    id="create-order-deliveryfromdate"
                    label={t("commonOrders.deliveryStart")}
                    value={values.deliveryStart}
                    onChange={(e) => onChange({ deliveryStart: e })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DatePicker
                    id="create-order-deliverytodate"
                    label={t("commonOrders.deliveryEnd")}
                    value={values.deliveryEnd}
                    onChange={(e) => onChange({ deliveryEnd: e })}
                    setmindate={moment(values.deliveryStart).toDate()}
                  />
                </Grid.Col>
              </Grid.Row>
            </div>
          </Grid.Col>
        </Grid.Row>
      </CSSTransition> */}

      <Grid.Row>
        <Grid.Col span={6}>
          <DatePicker
            id="create-order-lastDeliveryDate"
            label={t("commonOrders.lastDeliveryDate")}
            value={values.lastDeliveryDate}
            onChange={(e) => onChange({ lastDeliveryDate: e })}
          />
        </Grid.Col>
      </Grid.Row>

      {/* <Grid.Row>
        <Grid.Col span={6}>
          <DatePicker
            id="create-order-capturedate"
            label={t('commonOrders.captureDate')}
            value={values.catchDate}
            onChange={e => onChange({ catchDate: e })} />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePicker
            id="create-order-packetdate"
            label={t('commonOrders.packingDate')}
            value={values.packingDate}
            onChange={e => onChange({ packingDate: e })}
            setmindate={moment(values.catchDate).toDate()} />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <DatePicker
            id="create-order-expirationdate"
            label={t('commonOrders.expirationDate')}
            value={values.expirationDate}
            onChange={e => onChange({ expirationDate: e })} />
        </Grid.Col>
      </Grid.Row> */}

      <Grid.Row>
        <Grid.Col span={12}>
          <Checkbox
            label={t("commonOrders.differentAddress")}
            checked={isDifferentAddressChecked}
            onChange={(e) => onChangeDifferentAddressCB(e)}
          />
        </Grid.Col>
      </Grid.Row>

      <CSSTransition
        in={isDifferentAddressChecked}
        timeout={{
          enter: 1000,
          exit: 200,
        }}
        mountOnEnter
        unmountOnExit
        classNames="create-order-transitions"
        nodeRef={differentAddressNodeRef}
      >
        <Grid.Row>
          <Grid.Col span={12}>
            <div ref={differentAddressNodeRef}>
              <Grid.Row className="mbottom20">
                <Grid.Col span={6}>
                  <TextField
                    id="create-order-address"
                    name="address"
                    label={t("commonOrders.orderAddress")}
                    value={values.orderAddress.address}
                    onChange={(e) =>
                      onChange({
                        orderAddress: {
                          ...values.orderAddress,
                          address: e.target.value,
                        },
                      })
                    }
                    errormessage={fieldValidationAddressErrors.address}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Dropdown
                    id="add-address-country"
                    label={t("common.country")}
                    options={countryOptions}
                    onChange={(e) => setSelectedCountryHandler(e)}
                    value={selectedCountry}
                    errormessage={fieldValidationAddressErrors.countryCodeIso3}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col span={6}>
                  <TextField
                    id="create-order-postCode"
                    name="postCode"
                    type="number"
                    label={t("commonOrders.postCode")}
                    value={values.orderAddress.postCode}
                    onChange={(e) =>
                      onChange({
                        orderAddress: {
                          ...values.orderAddress,
                          postCode: e.target.value,
                        },
                      })
                    }
                    errormessage={fieldValidationAddressErrors.postCode}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextField
                    id="create-order-place"
                    name="place"
                    label={t("commonOrders.place")}
                    value={values.orderAddress.place}
                    onChange={(e) =>
                      onChange({
                        orderAddress: {
                          ...values.orderAddress,
                          place: e.target.value,
                        },
                      })
                    }
                    errormessage={fieldValidationAddressErrors.place}
                  />
                  <CTA
                    id="checkAddress"
                    disabled={
                      !values.orderAddress.postCode ||
                      !values.orderAddress.address ||
                      !values.orderAddress.countryCodeIso3 ||
                      !values.orderAddress.place
                    }
                    onClick={() => validateDifferentAddress()}
                  >
                    {t("commonOrders.checkDelivery")}
                  </CTA>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mtop20 align">
                <Grid.Col span={12}>
                  {differentAddressValidationMessage.message && (
                    <MessageBar
                      type={differentAddressValidationMessage.messageType}
                      noIcon
                      message={differentAddressValidationMessage.message}
                    />
                  )}
                </Grid.Col>
              </Grid.Row>
            </div>
          </Grid.Col>
        </Grid.Row>
      </CSSTransition>

      <Grid.Row>
        <Grid.Col span={12}>
          <TextField
            id="create-order-description"
            label={t("common.description")}
            placeholder={t("commonOrders.description")}
            value={values.description}
            onChange={(e) => onChange({ description: e.target.value })}
            errormessage={fieldValidationErrors.description}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12} className="mbottom20">
          <DropdownMulti
            id="target-company"
            label={t("common.companyToTradeWith")}
            closeMenuOnSelect={false}
            menuPlacement="top"
            options={companiesOptions}
            value={selectedCompanies}
            onChange={(e) => onChangeTargetCompanies(e)}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
