import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyNetwork, postSaveCompanyNetwork, getAllCompanies } from "../../../../services/Company";

import UserContext from "../../../../contexts/UserContext";
import DropdownMulti from "../../../../components/DropdownMulti/DropdownMulti";
import Grid from "../../../Grid/Grid";

import { SettingsFormContent, SettingsFormCTAs } from ".";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";
import CTA from "../../atoms/CTA";
import { Company, CompanyOption, NetworkCompany } from "types";
import { isViewer } from "@/utils/User";
import { TableIconsLockedNetwork } from "@/components/_Reviver/atoms/Icons/SettingsTableIcons";

export default function NetworkSettings() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany, user } = userContext;
  const [companyOptions, setCompanyOptions] = useState<CompanyOption[]>([]);
  const [existingNetwork, setExistingNetwork] = useState<CompanyOption[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<CompanyOption[]>([]);

  useEffect(() => {
    async function loadOptions() {
      async function asyncGetAllCompanies() {
        const allCompanies = await getAllCompanies();
        return allCompanies.data;
      }

      const allCompanies = await asyncGetAllCompanies();

      const _companyOptions = (allCompanies.companies as Company[]).map((company) => ({
        value: company.id,
        label: company.companyName,
        vatNo: company.vatNumber,
      }));
      setCompanyOptions(_companyOptions as CompanyOption[]);
    }

    loadOptions();
  }, []);

  useEffect(() => {
    // Prefill selected companies if company has an existing network
    async function preFillSelectedCompanies() {
      async function asyncGetCompanyNetwork() {
        const companies = await getCompanyNetwork(currentCompany.companyId);
        return companies.data;
      }

      const existingNetwork: NetworkCompany[] = await asyncGetCompanyNetwork();

      const _selectedCompanies = existingNetwork.map((company) => ({
        value: company.companyId,
        label: company.companyName,
        vatNo: company.vatno,
      }));

      setExistingNetwork(_selectedCompanies);
      setSelectedCompanies(_selectedCompanies);
    }

    preFillSelectedCompanies();
  }, [currentCompany.companyId]);

  async function saveHandler() {
    setExistingNetwork(selectedCompanies);
    // Map dropdown propNames to api propNames
    const _selectedCompanies: NetworkCompany[] = selectedCompanies.map((company: CompanyOption) => ({
      companyId: company.value,
      companyName: company.label,
      vatno: company.vatNo,
    }));

    await postSaveCompanyNetwork(currentCompany.companyId, _selectedCompanies);
  }

  return (
    <>
      <Heading order={2} styleOrder={4} tt="uppercase">
        {t("settings.network")}
      </Heading>
      {!isViewer(user, currentCompany) && (
        <>
          <SettingsFormContent>
            <Grid>
              <Grid.Row>
                <Grid.Col span={12}>
                  <Text size="md" strong={true} display="technical" tt="uppercase">
                    {t("settings.chooseNetwork")}
                  </Text>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col span={12}>
                  <DropdownMulti
                    id="companies"
                    label={t("settings.networks")}
                    value={selectedCompanies}
                    options={companyOptions}
                    onChange={(e) => setSelectedCompanies(e)}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </SettingsFormContent>
          <SettingsFormCTAs>
            <CTA id="save" size="lg" onClick={() => saveHandler()}>
              {t("common.save")}
            </CTA>
          </SettingsFormCTAs>
        </>
      )}
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="technical" tt="uppercase">
                {t("settings.networkList")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <table className="lightblue">
                <tbody>
                  {existingNetwork?.map((company: any, index: number) => (
                    <tr key={index}>
                      <td>{company.label}</td>
                      <td>
                        <TableIconsLockedNetwork />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
    </>
  );
}
