import React, { ChangeEvent, ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { createPassword } from "@/services/Login";
import { validateFieldsHelper } from "@/utils/ValidationHelper";
import TextField from "@/components/TextField/TextField";
import Modal, { ModalCTAs, ModalContent } from "@/components/_Reviver/organisms/Modal";
import Text from "@/components/_Reviver/atoms/Text";
import CTA from "@/components/_Reviver/atoms/CTA";
import MessageBar from "@/components/MessageBar/MessageBar";
import { createPasswordSchema } from "@/components/_Reviver/pages/Register/RegisterUserValidationSchema";
import { ValidationErrors } from "types";
import { HeadersContainer, SimpleHeader } from "@/components/_Reviver/molecules/Header";

import styles from "./styles.module.css";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Stack from "@/components/_Reviver/atoms/Stack";
import Heading from "@/components/_Reviver/atoms/Heading";

interface Props {
  setRedirection: any;
  setUserContext: any;
}

interface DialogOptions {
  open?: boolean;
  onClose?: () => void;
  message?: string;
  error?: ReactNode;
}

export default function CreatePassword(props: Props) {
  const { setRedirection, setUserContext } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({});

  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  const params = new URL(document.location.href).searchParams;
  const token = params.get("token");
  if (!token) {
    history.push("/");
  }

  function getMessageBar(exception: any) {
    return <MessageBar type="warning" message={exception.message} />;
  }

  async function asyncCreatePassword(password: string, token?: string) {
    return await createPassword(password, token);
  }

  const createPasswordHandler = async (e: any) => {
    e.preventDefault();

    if (password === rePassword) {
      try {
        const inputChecker = validateFieldsHelper(createPasswordSchema(t), {
          password: password,
          rePassword: rePassword,
        });
        setValidationErrors(inputChecker as any);
        if (Object.keys(inputChecker || {}).length === 0) {
          const response = await asyncCreatePassword(rePassword, token as string);
          const user = response.data;

          if (user) {
            setUserContext({ email: user.email });
            setDialogOptions({
              open: true,
              message: t("commonLoginAndUsers.createPasswordSuccess"),
              onClose: () => {
                setDialogOptions({ open: false });
                setRedirection("INTERNAL");
              },
            });
          }
        }
      } catch (e) {
        setDialogOptions({
          open: true,
          message: t("commonLoginAndUsers.createPasswordError"),
          error: getMessageBar(e),
          onClose: () => setDialogOptions({ open: false }),
        });
      }
    } else {
      setDialogOptions({
        open: true,
        error: t("commonLoginAndUsers.createPasswordMismatch"),
        onClose: () => {
          setDialogOptions({ open: false });
        },
      });
    }
  };

  const ctaClick = dialogOptions.onClose ? dialogOptions.onClose : () => {};

  return (
    <>
      <Helmet>
        <title>Reviver - {t("registerCompany.registerUserCreatePassword")}</title>
        <meta name="description" content="Reviver - Create password" />
      </Helmet>
      <Modal
        open={dialogOptions?.open}
        onClose={dialogOptions.onClose}
        headerText={dialogOptions.error ? "Error" : "Success"}
      >
        <ModalContent>
          <Text>{dialogOptions.message}</Text>
          {!!dialogOptions.error && dialogOptions.error}
        </ModalContent>
        <ModalCTAs>
          <CTA id="btn-close-dialog" onClick={ctaClick}>
            {"OK"}
          </CTA>
        </ModalCTAs>
      </Modal>
      <HeadersContainer>
        <SimpleHeader />
      </HeadersContainer>
      <div className={styles.loginRoot}>
        <div className={styles.loginContent}>
          <figure className={styles.splashImg}>
            <img src="/assets/img/splash/polymer-melt-logo.png" alt="Reviver logo" />
          </figure>

          <div className={styles.formPanel}>
            <SVGAsset className={styles.logo} name="reviver-logo" />
            <form onSubmit={createPasswordHandler}>
              <Stack gap="30px">
                <Stack gap="10px">
                  <Heading order={1} styleOrder={4} tt="uppercase">
                    {t("registerCompany.registerUserCreatePassword")}
                  </Heading>
                  <Text color="secondary">{t("registerCompany.registerUserCreatePasswordDescription")}</Text>
                </Stack>
                <TextField
                  id="txt-createpassword-pw"
                  label={t("registerCompany.registerUserPassword")}
                  type="password"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  errormessage={validationErrors.password}
                />
                <TextField
                  id="txt-createpassword-repeatpw"
                  label={t("registerCompany.registerUserPasswordRepeat")}
                  type="password"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setRePassword(e.target.value)}
                  errormessage={validationErrors.rePassword}
                />
                <CTA
                  id="btn-createpassword-save"
                  type="submit"
                  disabled={dialogOptions.open}
                  onClick={createPasswordHandler}
                >
                  {t("registerCompany.registerUserSavePassword")}
                </CTA>
              </Stack>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
