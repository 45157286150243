import { FC } from "react";
export interface LogoProps {
  variant?: "primary" | "secondary" | "simple";
}

const Logo: FC<LogoProps> = ({ variant = "primary" }) => {
  return {
    primary: <BaseLogo />,
    secondary: <AdminLogo />,
    simple: <LogoMark />,
  }[variant];
};

const BaseLogo: FC = () => (
  <svg width="173" height="32" viewBox="0 0 173 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M55.053 8.43653V15.2391H59.6837C62.2958 15.2391 63.8665 14.0019 63.8665 11.8398C63.8665 9.67759 62.2958 8.4404 59.6837 8.4404H55.053V8.43653ZM64.4541 26.6763L59.5693 18.3282H55.0511V26.6763H51.4307V5.31836H59.5965C64.5083 5.31836 67.5955 7.87417 67.5955 11.8398C67.5955 14.6767 66.0248 16.8118 63.2731 17.7678L68.6329 26.6782H64.4502L64.4541 26.6743V26.6763Z"
      fill="currentColor"
    />
    <path
      d="M74.5062 23.5581H85.4236V26.6763H70.8857V5.31836H85.1696V8.43653H74.5062V14.1435H84.9757V17.2616H74.5062V23.5581Z"
      fill="currentColor"
    />
    <path
      d="M103.759 5.31836H107.687L100.813 26.6763H93.6827L86.7812 5.31836H90.7371L97.2488 26.2264L103.762 5.31836H103.759Z"
      fill="currentColor"
    />
    <path d="M113.245 5.31836H109.625V26.6743H113.245V5.31836Z" fill="currentColor" />
    <path
      d="M132.092 5.31836H136.02L129.146 26.6763H122.016L115.114 5.31836H119.07L125.582 26.2264L132.095 5.31836H132.092Z"
      fill="currentColor"
    />
    <path
      d="M141.584 23.5581H152.502V26.6763H137.962V5.31836H152.248V8.43653H141.584V14.1435H152.054V17.2616H141.584V23.5581Z"
      fill="currentColor"
    />
    <path
      d="M159.298 8.43653V15.2391H163.929C166.541 15.2391 168.112 14.0019 168.112 11.8398C168.112 9.67759 166.541 8.4404 163.929 8.4404H159.298V8.43653ZM168.699 26.6763L163.814 18.3282H159.296V26.6763H155.676V5.31836H163.842C168.753 5.31836 171.841 7.87417 171.841 11.8398C171.841 14.6767 170.27 16.8118 167.518 17.7678L172.878 26.6782H168.695L168.699 26.6743V26.6763Z"
      fill="currentColor"
    />
    <path
      d="M18.4492 23.9952L9.22652 8.00097H18.4453L27.6679 23.9952H18.4492ZM27.666 7.99709H18.455L23.0624 0H4.61132L0 8.00097L9.22652 24.0029H18.4375L13.8262 32H32.2773L36.8886 23.999L27.6621 7.99709H27.666Z"
      fill="currentColor"
    />
  </svg>
);

const AdminLogo: FC = () => (
  <svg width="173" height="20" viewBox="0 0 173 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.074 5.27332V9.52492H36.9401C38.5568 9.52492 39.529 8.75168 39.529 7.40033C39.529 6.04898 38.5568 5.27574 36.9401 5.27574H34.074V5.27332ZM39.8926 16.6731L36.8693 11.4556H34.0728V16.6731H31.832V3.32446H36.8861C39.9263 3.32446 41.837 4.92185 41.837 7.40033C41.837 9.17345 40.8648 10.5078 39.1617 11.1053L42.4791 16.6744H39.8902L39.8926 16.6719V16.6731Z"
      fill="currentColor"
    />
    <path
      d="M46.1138 14.7243H52.871V16.6731H43.873V3.32446H52.7138V5.27332H46.1138V8.84016H52.5938V10.789H46.1138V14.7243Z"
      fill="currentColor"
    />
    <path
      d="M64.2188 3.32446H66.6504L62.3957 16.6731H57.9825L53.7109 3.32446H56.1594L60.1897 16.392L64.2212 3.32446H64.2188Z"
      fill="currentColor"
    />
    <path d="M70.0904 3.32446H67.8496V16.6719H70.0904V3.32446Z" fill="currentColor" />
    <path
      d="M81.7559 3.32446H84.1875L79.9328 16.6731H75.5196L71.248 3.32446H73.6965L77.7268 16.392L81.7583 3.32446H81.7559Z"
      fill="currentColor"
    />
    <path
      d="M87.6307 14.7243H94.3878V16.6731H85.3887V3.32446H94.2306V5.27332H87.6307V8.84016H94.1106V10.789H87.6307V14.7243Z"
      fill="currentColor"
    />
    <path
      d="M98.5955 5.27332V9.52492H101.462C103.078 9.52492 104.05 8.75168 104.05 7.40033C104.05 6.04898 103.078 5.27574 101.462 5.27574H98.5955V5.27332ZM104.414 16.6731L101.391 11.4556H98.5943V16.6731H96.3535V3.32446H101.408C104.448 3.32446 106.358 4.92185 106.358 7.40033C106.358 9.17345 105.386 10.5078 103.683 11.1053L107.001 16.6744H104.412L104.414 16.6719V16.6731Z"
      fill="currentColor"
    />
    <path
      d="M11.4188 14.997L5.7106 5.00061H11.4164L17.1246 14.997H11.4188ZM17.1234 4.99818H11.4224L14.2741 0H2.8541L0 5.00061L5.7106 15.0018H11.4116L8.5575 20H19.9775L22.8316 14.9994L17.121 4.99818H17.1234Z"
      fill="currentColor"
    />
    <path d="M115 3V17" stroke="currentColor" />
    <path
      d="M126.372 17C125.306 17 124.477 16.7174 123.886 16.1521C123.295 15.5737 123 14.8376 123 13.9437C123 13.4967 123.077 13.0892 123.231 12.7211C123.385 12.3399 123.61 12.0178 123.906 11.7549C124.214 11.4789 124.586 11.2685 125.023 11.1239C125.473 10.9662 125.986 10.8873 126.564 10.8873H129.743V10.0986C129.743 9.2047 129.506 8.52113 129.03 8.04789C128.568 7.57465 127.874 7.33803 126.95 7.33803C126.179 7.33803 125.582 7.51549 125.158 7.87042C124.747 8.21221 124.445 8.65916 124.252 9.21127H123.289C123.482 8.36995 123.899 7.68639 124.541 7.16056C125.184 6.6216 125.986 6.35211 126.95 6.35211C128.119 6.35211 129.037 6.6939 129.705 7.37747C130.373 8.04789 130.707 8.95493 130.707 10.0986V16.8028H129.936L129.743 15.2254H129.647C129.544 15.4488 129.397 15.6657 129.204 15.8761C129.024 16.0864 128.793 16.277 128.51 16.4479C128.241 16.6188 127.926 16.7502 127.566 16.8423C127.207 16.9474 126.808 17 126.372 17ZM126.372 16.0141C127.438 16.0141 128.266 15.7183 128.857 15.1268C129.448 14.5352 129.743 13.7465 129.743 12.7606V11.8732H126.564C125.691 11.8732 125.036 12.0704 124.599 12.4648C124.175 12.8592 123.963 13.3521 123.963 13.9437C123.963 14.5352 124.169 15.0282 124.58 15.4225C125.004 15.8169 125.601 16.0141 126.372 16.0141Z"
      fill="currentColor"
    />
    <path
      d="M137.347 17C136.718 17 136.14 16.8883 135.613 16.6648C135.086 16.4282 134.63 16.0864 134.245 15.6394C133.86 15.1793 133.558 14.6207 133.339 13.9634C133.121 13.3061 133.012 12.5437 133.012 11.6761C133.012 10.8085 133.121 10.046 133.339 9.38873C133.558 8.73146 133.86 8.17934 134.245 7.7324C134.63 7.2723 135.086 6.93052 135.613 6.70704C136.14 6.47042 136.718 6.35211 137.347 6.35211C138.079 6.35211 138.741 6.523 139.331 6.86479C139.935 7.19343 140.43 7.67981 140.815 8.32394H140.911V3H141.875V16.8028H141.104L140.911 15.0282H140.815C140.43 15.6723 139.935 16.1653 139.331 16.507C138.741 16.8357 138.079 17 137.347 17ZM137.443 16.0141C137.918 16.0141 138.368 15.9221 138.792 15.738C139.216 15.5408 139.582 15.2582 139.89 14.8901C140.211 14.5089 140.462 14.0554 140.641 13.5296C140.821 12.9906 140.911 12.3728 140.911 11.6761C140.911 10.9925 140.821 10.3812 140.641 9.84225C140.462 9.30329 140.211 8.84977 139.89 8.48169C139.582 8.10047 139.216 7.81784 138.792 7.6338C138.368 7.43662 137.918 7.33803 137.443 7.33803C136.955 7.33803 136.499 7.43662 136.075 7.6338C135.664 7.81784 135.298 8.10047 134.977 8.48169C134.669 8.84977 134.425 9.30329 134.245 9.84225C134.065 10.3812 133.975 10.9925 133.975 11.6761C133.975 12.3728 134.065 12.9906 134.245 13.5296C134.425 14.0554 134.669 14.5089 134.977 14.8901C135.298 15.2582 135.664 15.5408 136.075 15.738C136.499 15.9221 136.955 16.0141 137.443 16.0141Z"
      fill="currentColor"
    />
    <path
      d="M144.858 6.5493H145.629L145.821 8.12676H145.918C146.213 7.53521 146.605 7.09484 147.093 6.80563C147.594 6.50329 148.133 6.35211 148.711 6.35211C149.443 6.35211 150.079 6.55587 150.619 6.96338C151.158 7.35775 151.55 7.877 151.794 8.52113H151.89C152.224 7.73239 152.68 7.17371 153.258 6.84507C153.836 6.51643 154.44 6.35211 155.069 6.35211C155.544 6.35211 155.994 6.4507 156.418 6.64789C156.842 6.83193 157.208 7.10141 157.516 7.45634C157.837 7.81127 158.088 8.24507 158.267 8.75775C158.447 9.27042 158.537 9.84225 158.537 10.4732V16.8028H157.574V10.4732C157.574 9.98686 157.503 9.55305 157.362 9.17183C157.233 8.77746 157.047 8.44883 156.803 8.18592C156.572 7.90986 156.296 7.69953 155.975 7.55493C155.666 7.41033 155.333 7.33803 154.973 7.33803C154.588 7.33803 154.221 7.4169 153.875 7.57465C153.541 7.71925 153.245 7.92958 152.988 8.20563C152.744 8.48169 152.545 8.8169 152.391 9.21127C152.25 9.59249 152.179 10.0131 152.179 10.4732V16.8028H151.216V10.4732C151.216 9.98686 151.145 9.55305 151.004 9.17183C150.876 8.77746 150.689 8.44883 150.445 8.18592C150.214 7.90986 149.938 7.69953 149.617 7.55493C149.308 7.41033 148.975 7.33803 148.615 7.33803C148.255 7.33803 147.908 7.41033 147.574 7.55493C147.241 7.69953 146.939 7.90986 146.669 8.18592C146.412 8.44883 146.207 8.77746 146.052 9.17183C145.898 9.55305 145.821 9.98686 145.821 10.4732V16.8028H144.858V6.5493Z"
      fill="currentColor"
    />
    <path
      d="M161.34 6.5493H162.303V16.8028H161.34V6.5493ZM161.822 4.77465C161.603 4.77465 161.417 4.70235 161.263 4.55775C161.122 4.4 161.051 4.20939 161.051 3.98592C161.051 3.76244 161.122 3.5784 161.263 3.4338C161.417 3.27606 161.603 3.19718 161.822 3.19718C162.04 3.19718 162.22 3.27606 162.361 3.4338C162.515 3.5784 162.592 3.76244 162.592 3.98592C162.592 4.20939 162.515 4.4 162.361 4.55775C162.22 4.70235 162.04 4.77465 161.822 4.77465Z"
      fill="currentColor"
    />
    <path
      d="M165.197 6.5493H165.968L166.16 8.12676H166.257C166.591 7.53521 167.014 7.09484 167.528 6.80563C168.055 6.50329 168.659 6.35211 169.339 6.35211C169.866 6.35211 170.354 6.4507 170.804 6.64789C171.253 6.83193 171.638 7.10141 171.96 7.45634C172.294 7.81127 172.55 8.24507 172.73 8.75775C172.91 9.27042 173 9.84883 173 10.493V16.8028H172.037V10.493C172.037 10.0197 171.96 9.59249 171.805 9.21127C171.664 8.8169 171.465 8.48169 171.208 8.20563C170.964 7.92958 170.669 7.71925 170.322 7.57465C169.988 7.4169 169.628 7.33803 169.243 7.33803C168.806 7.33803 168.402 7.42347 168.029 7.59437C167.657 7.75211 167.329 7.97559 167.047 8.26479C166.777 8.54085 166.559 8.86948 166.392 9.2507C166.237 9.63193 166.16 10.046 166.16 10.493V16.8028H165.197V6.5493Z"
      fill="currentColor"
    />
  </svg>
);

const LogoMark: FC = () => (
  <svg width="57" height="50" viewBox="0 0 57 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.7716 12.4954H28.5324L35.655 0H7.12859L0 12.5015L14.2632 37.5046H28.5023L21.3737 50H49.9001L57.0287 37.4985L42.7655 12.4954H42.7716ZM28.5204 37.4924L14.2632 12.5015H28.5144L42.7716 37.4924H28.5204Z"
      fill="white"
    />
  </svg>
);

export default Logo;
