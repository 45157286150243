import { FC, PropsWithChildren } from "react";
import styles from "./styles.module.css";
import Stack from "@/components/_Reviver/atoms/Stack";
import Text from "@/components/_Reviver/atoms/Text";
import CTA from "@/components/_Reviver/atoms/CTA";
import Group from "@/components/_Reviver/atoms/Group";
import Dropdown from "@/components/Dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";
import { Order } from "types";
import Heading from "../../atoms/Heading";

interface TradeHeaderProps {
  orders: Order[];
  createNew: () => void;
  companyName: string;
}

interface ContractsHeaderProps {
  companyName: string;
  sortOptions: any[];
  totalContracts: number;
}

interface SubHeaderProps {
  variant?: "primary" | "secondary";
}

const SubHeaderBase: FC<PropsWithChildren<SubHeaderProps>> = ({ variant = "primary", ...props }) => {
  const rootClass = `${styles.subHeaderRoot} ${styles[variant]}`;
  return (
    <header className={rootClass}>
      <div className={styles.inner}>{props.children}</div>
    </header>
  );
};

export const TradeHeader: FC<TradeHeaderProps> = (props) => {
  const { orders, createNew, companyName } = props;
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const isMobile = width > 767;

  return (
    <SubHeaderBase>
      <Stack gap="4px">
        <Heading order={2} styleOrder={5} tt="uppercase" strong={true}>
          {companyName}
        </Heading>
        <Heading order={3} styleOrder={6} tt="uppercase">
          {orders.length} {t("commonOrders.activeOrders")}
        </Heading>
      </Stack>
      <CTA size="lg" intent="primary" onClick={createNew}>
        {t("commonOrders.newOrder")}
      </CTA>
    </SubHeaderBase>
  );
};

export const ContractsHeader: FC<ContractsHeaderProps> = (props) => {
  const { companyName, totalContracts } = props;
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const sortOptions = [
    {
      value: "date",
      label: "Creation date",
    },
    {
      value: "partner",
      label: "Trading partner",
    },
    {
      value: "remaining",
      label: "Amount remaining",
    },
  ];

  return (
    <SubHeaderBase variant="secondary">
      <Group grow={true} justify="space-between" gap="20px">
        <Stack gap="4px">
          <Heading order={2} styleOrder={5} tt="uppercase" strong={true}>
            {companyName}
          </Heading>
          <Heading order={3} styleOrder={6} tt="uppercase">
            {totalContracts} {t("contracts.activeContracts")}
          </Heading>
        </Stack>
        <div className={styles.sortOptions}>
          <Group>
            <Text size="sm" strong={true} nowrap={true}>
              {t("commonComponents.sortBy")}
            </Text>
            <Dropdown id="options" options={sortOptions} />
          </Group>
        </div>
      </Group>
    </SubHeaderBase>
  );
};
