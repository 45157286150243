import React, { FC, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { postInvoice } from "../../../../../services/Orders";
import { ErrorDialogContext } from "../../../../../contexts/ErrorDialogContext";
import RadioButton from "../../../../RadioButton/RadioButton";
import SVGAsset from "../../../../SVGAsset/SVGAsset";
import Grid from "../../../../Grid/Grid";
import Spinner from "../../../../Spinner/Spinner";

import styles from "./../styles.module.css";
import Text from "@/components/_Reviver/atoms/Text";
import CTA from "@/components/_Reviver/atoms/CTA";

interface Props {
  orderId: number;
  taskId: number;
  onCloseCB: () => void;
}

const PaymentTask: FC<Props> = ({ orderId, taskId, onCloseCB }) => {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};
  const history = useHistory();

  const [selected, setSelected] = useState();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [buttonText, setButtonText] = useState(t("dashboard.selectOne"));

  const onClickHandler = async () => {
    setLoadingPayment(true);
    // TODO: if we decide to remove card option -> remove the following unused code.
    if (selected === "card") {
      history.push(`checkout/?orderId=${orderId}`);
      onCloseCB();
    } else {
      try {
        await postInvoice(orderId, taskId);
        onCloseCB();
      } catch (error) {
        setErrorDialogOptions({
          message: "Saving invoice choice failed",
          error: error as Error,
        });
        showErrorDialog(true);
      }
    }
    setLoadingPayment(false);
  };

  const onChangeRB = (selected: any) => {
    setSelected(selected);

    if (selected === "card") setButtonText(t("dashboard.selectpayment"));
    else if (selected === "invoice") setButtonText(t("dashboard.confirmPayment"));
  };

  return (
    <Grid className={styles.paymentTask}>
      <Spinner loading={loadingPayment} inline={true} />
      <Grid.Row>
        <Grid.Col span={12} className={styles.paymentTaskChoosePayment}>
          <SVGAsset name="credit-card" />
          <Text>{t("dashboard.choosepayment")}</Text>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <RadioButton
            value="invoice"
            label={t("dashboard.invoice")}
            selected={selected}
            onClick={() => onChangeRB("invoice")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <RadioButton
            value="card"
            label={t("dashboard.card")}
            isDisabled={true}
            selected={selected}
            onClick={() => onChangeRB("card")}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12} className={styles.paymentTaskCtas}>
          <CTA id="payment-submit" disabled={!selected} onClick={() => onClickHandler()}>
            {buttonText}
          </CTA>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default PaymentTask;
