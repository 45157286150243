export const SalesStepTypeEnum = {
  ORDER_DETAILS: 0,
  IMAGE_UPLOAD: 1,
  LOCATION_DELIVERY: 2,
  PACKING: 3,
};

Object.freeze(SalesStepTypeEnum);

export const PurchaseStepTypeEnum = {
  ORDER_DETAILS: 0,
  LOCATION_DELIVERY: 1,
};

Object.freeze(PurchaseStepTypeEnum);
