import { FC } from "react";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import Text from "../../atoms/Text";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

interface Props {
  onClick: () => void;
}

const CreateOrderCta: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.createOrderCtaRoot} onClick={onClick}>
      <div className={styles.iconLabel}>
        <SVGAsset name="plus-circle" />
        <Text display="technical" size="sm" strong={true} tt="uppercase">
          {t("commonOrders.newOrder")}
        </Text>
      </div>
    </div>
  );
};

export default CreateOrderCta;
