import { FC } from "react";
import styles from "./styles.module.css";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Text from "../../atoms/Text";
import Stack from "../../atoms/Stack";
import Heading from "../../atoms/Heading";
import Group from "../../atoms/Group";
import CTA from "../../atoms/CTA";
import { useTranslation } from "react-i18next";

interface Props {
  contract: any;
}

const ContractCard: FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const saleType = "sale";

  const config = {
    purchase: {
      iconName: "purchaseIndicatorLarge",
      progressColor: "green",
      companyIcon: "arrowPurchaseBlack",
      partnerIcon: "arrowSaleBlack",
    },
    sale: {
      iconName: "salesIndicatorLarge",
      progressColor: "red",
      companyIcon: "arrowPurchaseBlack",
      partnerIcon: "arrowSaleBlack",
    },
  }[saleType];

  return (
    <div className={styles.contractCardRoot}>
      <SVGAsset name={config.iconName} width="36" height="36" className={styles.icon} />
      <Text size="xs" className={styles.dateCreated} color="secondary">
        23.07.2024
      </Text>
      <Stack gap="17px">
        <Stack gap="2px">
          <Text span={true} size="xs" tt="uppercase" color="tertiary">
            Replast HDPE rør
          </Text>
          <Heading order={4} styleOrder={5} strong={true}>
            HDPE rør svart
          </Heading>
        </Stack>
        <Stack gap={0} className={styles[saleType]}>
          <Text size="md" strong={true}>
            <SVGAsset name={config.companyIcon} width="12" height="12" className={styles.tradeIcon} />
            Oxidane Venture AS
          </Text>
          <Text size="md" strong={true}>
            <SVGAsset name={config.partnerIcon} width="12" height="12" className={styles.tradeIcon} />
            Replast AS
          </Text>
        </Stack>
        <div className={styles.progressBar}>
          <span className={`${styles.percent} ${styles[saleType]}`} style={{ width: "70%" }} />
        </div>
        <Group>
          <CTA intent="primary" size="sm" onClick={() => {}}>
            {t("common.details")}
          </CTA>
          <CTA intent="secondary" size="sm" onClick={() => {}}>
            {t("commonOrders.newOrder")}
          </CTA>
        </Group>
      </Stack>
    </div>
  );
};
export default ContractCard;
