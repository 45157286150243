import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "../Grid/Grid";
import SVGAsset from "../SVGAsset/SVGAsset";
import FilterContent from "./FilterContent";
import Checkbox from "../../components/Checkbox/Checkbox";
import DatePicker from "../../components/DatePicker/DatePicker";
import TextField from "../../components/TextField/TextField";

import "./Filters.css";

export default function Filters(props) {
  const { t, i18n } = useTranslation();
  const { filters, filter: activeFilter, setFilter } = props;
  const [openFilters, setOpenFilters] = useState([]);
  const [deferredFilter, setDeferredFilter] = useState(activeFilter);

  function handleFilterToggle(index) {
    const isOpen = openFilters.includes(index);
    let newList;
    if (isOpen) {
      newList = openFilters.filter((filterIndex) => filterIndex !== index);
    } else {
      newList = openFilters.filter(() => true).concat(index);
    }
    setOpenFilters(newList);
  }

  function isFilterOpen(index) {
    return openFilters.includes(index) ? "expanded" : "collapsed";
  }

  function getKeyByAlias(alias) {
    switch (alias) {
      case "salesItems":
        return "plastic";
      case "qualities":
        return "qualityTypes";
      case "sizes":
        return "sizeTypes";
      case "gears":
        return "tools";
      default:
        return alias;
    }
  }

  function filterSelected(e, filterName, choice) {
    const checked = e.checked;
    if (checked) {
      setFilter((previousFilter) => {
        const filterArray = previousFilter[filterName] || [];
        return {
          ...previousFilter,
          [filterName]: [...filterArray, choice.id],
        };
      });
    } else {
      setFilter((previousFilter) => {
        const filterArray = previousFilter[filterName] || [];
        return {
          ...previousFilter,
          [filterName]: filterArray.filter((f) => f !== choice.id),
        };
      });
    }
  }

  function onChangeDeferred(value, variable) {
    setDeferredFilter((previousFilter) => ({
      ...previousFilter,
      [variable]: value,
    }));
  }

  function onChangeActive(value, variable) {
    setFilter((previousFilter) => ({
      ...previousFilter,
      [variable]: value,
    }));
  }

  function applyDeferred() {
    setFilter((previousFilter) => ({
      ...previousFilter,
      ...deferredFilter,
    }));
  }

  function renderMinMaxFields(filterName, minimumVariable, maximumVariable) {
    return (
      <Grid className="nogap">
        <Grid.Col span={12}>
          <div className="flex align gap10 content-input">
            <TextField
              className={`${filterName}-field ${minimumVariable}`}
              label={t("commonOrders.from")}
              onChange={(e) => onChangeDeferred(e.target.value, minimumVariable)}
              onBlur={applyDeferred}
              value={deferredFilter[minimumVariable] || ""}
            />
            <TextField
              className={`${filterName}-field ${maximumVariable}`}
              label={t("commonOrders.to")}
              onChange={(e) => onChangeDeferred(e.target.value, maximumVariable)}
              onBlur={applyDeferred}
              value={deferredFilter[maximumVariable] || ""}
            />
          </div>
        </Grid.Col>
      </Grid>
    );
  }

  function renderDatesFromTo(filterName, from, to) {
    return (
      <Grid className="nogap">
        <Grid.Col span={12}>
          <div className="flex align gap10 content-input">
            <DatePicker
              label={t("commonOrders.from")}
              className={`${filterName}-field ${from}`}
              onChange={(date) => onChangeActive(date, from)}
              value={activeFilter[from]}
            />
            <DatePicker
              label={t("commonOrders.to")}
              className={`${filterName}-field ${to}`}
              onChange={(date) => onChangeActive(date, to)}
              value={activeFilter[to]}
            />
          </div>
        </Grid.Col>
      </Grid>
    );
  }

  function renderCheckboxItem(filterName, choice, index, isActive) {
    const { value } = choice;
    const checked = activeFilter[filterName] && activeFilter[filterName].includes(choice.id);
    const active = isActive ? "active" : "disabled";
    const translationKey = `common.${getKeyByAlias(filterName)}.${value}`;
    const translationExists = i18n.exists(translationKey);

    return (
      <Grid key={`${filterName}-option ${value}-${index}`}>
        <Grid.Col span={12}>
          <div className={`flex gap10 align filter-check-item ${active} ${checked ? "checked" : ""}`}>
            <Checkbox
              label={translationExists ? t(translationKey) : value}
              className={`${filterName}-option ${value}-${index} mbottom10`}
              onChange={(e) => filterSelected(e, filterName, choice)}
              checked={!!checked}
            />
          </div>
        </Grid.Col>
      </Grid>
    );
  }

  if (!filters) {
    return null;
  }

  return (
    <div className="filters-component">
      {Object.keys(filters).map((filterName, index) => {
        const open = openFilters.includes(index);
        const filter = filters[filterName];
        const isArrayOfChoices = filter.items && filter.activeItems;
        const isEmpty = isArrayOfChoices && filter.items.length === 0;
        const isDelivery = filterName === "delivery";
        const isAmount = filterName === "amount";
        const isPrice = filterName === "price";

        let isActiveFilter = false;
        if (isDelivery) isActiveFilter = activeFilter["earliestDeliveryDate"] || activeFilter["latestDeliveryDate"];
        else if (isAmount) isActiveFilter = activeFilter["minimumAmount"] || activeFilter["maximumAmount"];
        else if (isPrice) isActiveFilter = activeFilter["minimumPrice"] || activeFilter["maximumPrice"];
        else isActiveFilter = activeFilter[filterName] && activeFilter[filterName].length > 0;

        if (isArrayOfChoices && isEmpty) {
          return null;
        }

        if (isActiveFilter && !open) {
          handleFilterToggle(index);
        }

        if (filterName === "price" || filterName === "delivery") return null;
        else
          return (
            <div
              className={`filter unselectable ${isFilterOpen(index)}`}
              key={"filter-component-heading-" + index}
              id={"filter-component-heading-" + filterName}
            >
              <div className="heading" onClick={() => handleFilterToggle(index)}>
                {t("commonOrders." + filterName)}
                <SVGAsset name={open ? "filter-minus" : "filter-plus"} className="right" />
              </div>
              <div className="content">
                <FilterContent name={filterName} open={open}>
                  {isArrayOfChoices &&
                    filter.items.map((choice, index) => {
                      const isActive = !!filter.activeItems.find((item) => item.id === choice.id);
                      return renderCheckboxItem(filterName, choice, index, isActive);
                    })}
                  {isDelivery && renderDatesFromTo(filterName, "earliestDeliveryDate", "latestDeliveryDate")}
                  {isAmount && renderMinMaxFields(filterName, "minimumAmount", "maximumAmount")}
                  {isPrice && renderMinMaxFields(filterName, "minimumPrice", "maximumPrice")}
                </FilterContent>
              </div>
            </div>
          );
      })}
    </div>
  );
}
