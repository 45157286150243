import { FC } from "react";

interface Props {
  width: string;
  height: string;
}

const Network: FC<Props> = ({ width = "32", height = "32" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.53313 26.3736C10.1384 26.3736 11.4398 25.0723 11.4398 23.467C11.4398 21.8616 10.1384 20.5603 8.53313 20.5603C6.92782 20.5603 5.62646 21.8616 5.62646 23.467C5.62646 25.0723 6.92782 26.3736 8.53313 26.3736Z"
        fill="currentColor"
      />
      <path
        d="M5.62646 5.62695V9.40029C14.9998 9.40029 22.5998 17.0003 22.5998 26.3736H26.3731C26.3731 14.9203 17.0798 5.62695 5.62646 5.62695ZM5.62646 13.1736V16.947C10.8265 16.947 15.0531 21.1736 15.0531 26.3736H18.8265C18.8265 19.0803 12.9198 13.1736 5.62646 13.1736Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Network;
