import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import Text from "../../atoms/Text";
import DatePicker from "../../../DatePicker/DatePicker";

interface Props {
  fromDate: any;
  toDate: any;
  setFromDate: (e: SyntheticEvent) => void;
  setToDate: (e: SyntheticEvent) => void;
}

const CompareDates: FC<Props> = ({ fromDate, toDate, setFromDate, setToDate }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.compareDatesRoot}>
      <div className={styles.inner}>
        <DatePicker value={fromDate} onChange={(e: SyntheticEvent) => setFromDate(e)} setmaxdate={new Date()} />
        <Text display="technical" size="sm" tt="uppercase">
          {t("dashboard.comparedTo")}
        </Text>
        <DatePicker value={toDate} onChange={(e: SyntheticEvent) => setToDate(e)} setmaxdate={new Date()} />
      </div>
    </div>
  );
};
export default CompareDates;
