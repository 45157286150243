import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SettingsNavItem from "../../atoms/SettingsNavItem/";
import styles from "./styles.module.css";

export interface SettingsNavigationItem {
  url: string;
  translationsKey: string;
  icon: string;
}

interface Props {}
const navItems: SettingsNavigationItem[] = [
  {
    url: "/settings/account",
    translationsKey: "settings.youraccount",
    icon: "user",
  },
  {
    url: "/settings/settings",
    translationsKey: "settings.companydetails",
    icon: "info",
  },
  {
    url: "/settings/team",
    translationsKey: "settings.team",
    icon: "users",
  },
  {
    url: "/settings/orderTemplates",
    translationsKey: "settings.templates",
    icon: "template",
  },
  {
    url: "/settings/network",
    translationsKey: "settings.network",
    icon: "network",
  },
  {
    url: "/settings/newsletter",
    translationsKey: "settings.advertisement",
    icon: "message",
  },
];

const SettingsNavigation: FC<Props> = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <nav className={styles.settingsNavRoot}>
      {navItems.map((item: any, i) => (
        <SettingsNavItem
          href={item.url}
          text={t(item.translationsKey)}
          isActive={location.pathname === item.url}
          icon={item.icon}
          key={i}
        />
      ))}
    </nav>
  );
};

export default SettingsNavigation;
