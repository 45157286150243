import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SVGAsset from "../SVGAsset/SVGAsset";

import "./Feedback.css";
import CTA from "@/components/_Reviver/atoms/CTA";

export default function Feedback(props) {
  const { t } = useTranslation();
  const { submitFeedbackCB } = props;
  const [selectedStar, setSelectedStar] = useState(0);
  const [feedbackText, setFeedbackText] = useState("");

  function starClick(star) {
    setSelectedStar(star);
  }

  async function submitHandler() {
    await submitFeedbackCB(`R${selectedStar}`, feedbackText);
  }

  return (
    <div className="feedback-container">
      <div className="stars-wrapper mbottom10">
        <input type="radio" id="star-1" value="1" checked={selectedStar === 5} onChange={() => starClick(5)} />
        <label htmlFor="star-1">
          <SVGAsset name="star-outline" />
        </label>

        <input type="radio" id="star-2" value="2" checked={selectedStar === 4} onChange={() => starClick(4)} />
        <label htmlFor="star-2">
          <SVGAsset name="star-outline" />
        </label>

        <input type="radio" id="star-3" value="3" checked={selectedStar === 3} onChange={() => starClick(3)} />
        <label htmlFor="star-3">
          <SVGAsset name="star-outline" />
        </label>

        <input type="radio" id="star-4" value="4" checked={selectedStar === 2} onChange={() => starClick(2)} />
        <label htmlFor="star-4">
          <SVGAsset name="star-outline" />
        </label>

        <input type="radio" id="star-5" value="5" checked={selectedStar === 1} onChange={() => starClick(1)} />
        <label htmlFor="star-5">
          <SVGAsset name="star-outline" />
        </label>
      </div>
      <textarea value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)} />
      <CTA id="submit" disabled={selectedStar === 0} onClick={() => submitHandler()}>
        {t("tasks.submit")}
      </CTA>
    </div>
  );
}
