import React from "react";
import PropTypes from "prop-types";

import MoonLoader from "react-spinners/MoonLoader";

import "./Spinner.css";

const override = `
  display: block;
`;

function Spinner(props) {
  const { loading, inline } = props;
  const _inline = inline ? "inline" : "";

  if (!loading) return null;

  return (
    <div className={`spinner ${_inline}`}>
      <MoonLoader loading={loading} color={"var(--color-black)"} size={inline ? 35 : 150} css={override} />
    </div>
  );
}

export default Spinner;

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  inline: PropTypes.bool,
};
