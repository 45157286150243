import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const PanelContext = createContext({});

export const Provider = (props) => {
  const { open: initialOpen = false, children } = props;

  const [open, setOpen] = useState(initialOpen);
  const [data, setData] = useState({});

  const panelContext = {
    open,
    setOpen,
    data,
    setData,
  };

  return <PanelContext.Provider value={panelContext}>{children}</PanelContext.Provider>;
};

export const { Consumer } = PanelContext;

Provider.propTypes = {
  open: PropTypes.bool,
};
