import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { getAllTempCompaniesToVerify } from "../../services/VerifyCompany";
import { postCreateCompany, postDeclineCompany } from "../../services/VerifyCompany";
import { PanelContext } from "../../contexts/PanelContext";

import Grid from "../../components/Grid/Grid";
import VerifyCustomerTable from "../../components/Tables/VerifyCustomerTable";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import ApplicationTabContent from "./TabContent/ApplicationTabContent";
import TeamTabContent from "./TabContent/TeamTabContent";
import Modal, { ModalCTAs, ModalContent } from "../../components/_Reviver/organisms/Modal";
import Text from "../../components/_Reviver/atoms/Text";
import CTA from "../../components/_Reviver/atoms/CTA";
import Spinner from "../../components/Spinner/Spinner";

import "./VerifyCustomer.css";

export default function VerifyCustomer() {
  const { t } = useTranslation();

  const panelContext = useContext(PanelContext);
  const { setOpen, data, setData } = panelContext;
  const [verifyCustomerData, setVerifyCustomerData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal(message) {
    setModalMessage(message);
    setIsModalOpen(true);
  }

  async function asyncGetAllTempCompaniesToVerify() {
    setIsLoadingCompanies(true);
    const response = await getAllTempCompaniesToVerify();
    setVerifyCustomerData(response.data.companies);
    setIsLoadingCompanies(false);
  }

  useEffect(() => {
    asyncGetAllTempCompaniesToVerify();
  }, []);

  function buildSidePanel(company) {
    const {
      partialCompany: { name },
    } = company;

    async function callApi(func) {
      try {
        await func();
      } catch (e) {
        if (e.request) {
          alert("Feil ved behandling av forespørsel. Feil: " + e.response.statusText);
        } else {
          alert("Kunne ikke sende forespørsel. Feil: " + e);
        }
        throw e;
      }
    }

    async function asyncPostCreateCompany() {
      setOpen(false);
      await callApi(async () => await postCreateCompany(company.partialCompanyId));
      asyncGetAllTempCompaniesToVerify();
      openModal("Company created");
    }

    const acceptHandler = () => {
      asyncPostCreateCompany();
    };

    async function asyncPostDeclinePartialCompany() {
      setOpen(false);
      await callApi(async () => await postDeclineCompany(company.partialCompanyId));
      asyncGetAllTempCompaniesToVerify();
      openModal("Company declined");
    }

    const declineHandler = () => {
      asyncPostDeclinePartialCompany();
    };

    return (
      <div className="full flex col">
        <div className="flex gap10 align pad10">
          <SVGAsset className="company-icon" name="company-icon" />
          <h3 className="no-margin">{name}</h3>
        </div>
        <Tabs className="verify-customer-tabs">
          <Tab className="overflow-containr" title={t("verifyCustomer.application")} active>
            <ApplicationTabContent company={company} />
          </Tab>
          <Tab className="overflow-containr" title={t("verifyCustomer.team")}>
            <TeamTabContent company={company} />
          </Tab>
          <Tab className="overflow-containr" title={t("verifyCustomer.files")}>
            Filer
          </Tab>
        </Tabs>
        <div className="button-row-overflow">
          <div className="button-row flex align right gap20 pad20">
            <CTA id="btn-verifycustomer-deny" className="knapp" intent="secondary" onClick={declineHandler}>
              text={t("common.deny")}
            </CTA>
            <CTA id="btn-verifycustomer-accept" className="knapp" onClick={acceptHandler}>
              {t("common.accept")}
            </CTA>
          </div>
        </div>
      </div>
    );
  }

  function rowSelected(company, e) {
    const target = e.target;
    setData({
      ...data,
      children: buildSidePanel(company),
      ref: target,
    });
    setOpen(true);
  }

  return (
    <div>
      <Helmet>
        <title>Admin - Reviver - {t("verifyCustomer.newapplications")}</title>
        <meta name="description" content="Admin - Reviver - {t('verifyCustomer.newapplications')}" />
      </Helmet>
      <Grid className="verify-customer">
        <Modal open={isModalOpen} onClose={closeModal}>
          <ModalContent>
            <Text>{modalMessage}</Text>
          </ModalContent>
          <ModalCTAs>
            <CTA id="btn-verifycustomer-ok" onClick={closeModal}>
              {t("common.ok")}
            </CTA>
          </ModalCTAs>
        </Modal>
        <Grid.Col span={12}>
          <Spinner loading={isLoadingCompanies} />
          <VerifyCustomerTable data={verifyCustomerData} onSelect={rowSelected} />
        </Grid.Col>
      </Grid>
    </div>
  );
}
