import { FC } from "react";
import { CSSDimension } from "types";

interface Props {
  height?: CSSDimension;
  width?: CSSDimension;
}

const Space: FC<Props> = ({ height, width }) => (
  <div style={{ ...(height ? { height } : {}), ...(width ? { width } : {}) }} />
);
export default Space;
