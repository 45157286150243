import React, { FC, SyntheticEvent, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getLoginUrl } from "@/services/Login";
import { useTranslation } from "react-i18next";
import { validateFieldsHelper } from "@/utils/ValidationHelper";
import { loginCheckValidationSchema } from "./LoginCheckValidationSchema";

import TextField from "@/components/TextField/TextField";
import SVG from "@/components/SVGAsset/SVGAsset";
import CTA from "@/components/_Reviver/atoms/CTA";
import Heading from "@/components/_Reviver/atoms/Heading";
import styles from "./styles.module.css";
import { HeadersContainer, SimpleHeader } from "@/components/_Reviver/molecules/Header";
import Stack from "@/components/_Reviver/atoms/Stack";
import Text from "@/components/_Reviver/atoms/Text";
import { ValidationErrors } from "types";

interface Props {
  setUserContext: any;
  setRedirection: any;
}

function parseCallbackUrl() {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

const LoginCheckEmail: FC<Props> = ({ setRedirection, setUserContext }) => {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  const params = parseCallbackUrl();
  const externalRef = params.get("externalRef");
  const emailParam = params.get("email") || "";
  const signUp = params.get("signup");
  const local = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
  const port = local ? window.location.host.split(":")[1] || "80" : null;

  async function asyncGetLoginUrl(email: any, externalRef: any, port: any) {
    return await getLoginUrl(email, externalRef, port);
  }

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      const inputChecker = validateFieldsHelper(loginCheckValidationSchema(t), {
        email: email,
      });
      setValidationErrors(inputChecker as any);
      if (Object.keys(inputChecker as any).length === 0) {
        const response = await asyncGetLoginUrl(email, externalRef, port);
        const { type, url } = response.data;

        if (type === "INTERNAL") {
          setUserContext({ email: email });
          setRedirection("INTERNAL");
        } else if (type === "EXTERNAL") {
          setUserContext({ redirectUrl: url });
          setRedirection("EXTERNAL");
        } else if (type === "NEW_USER") {
          setUserContext({ email: email });
          setRedirection("NEW_USER");
        }
      }
    } catch (e) {
      alert("E-post-sjekk feilet: " + e);
    }
  };

  const [email, _setEmail] = useState(emailParam);
  const setEmail = (value: any) => {
    let email = value;
    if (value) {
      email = value.trim();
    }
    _setEmail(email);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - Login Check email</title>
        <meta name="description" content="Reviver - Login check email" />
      </Helmet>
      <HeadersContainer>
        <SimpleHeader />
      </HeadersContainer>
      <div className={styles.loginRoot}>
        <div className={styles.loginContent}>
          <figure className={styles.splashImg}>
            <img src="/assets/img/splash/polymer-melt-logo.png" alt="Reviver logo" />
          </figure>

          <div className={styles.formPanel}>
            <SVG className={styles.logo} name="reviver-logo" />
            <form onSubmit={handleSubmit}>
              <Stack gap="30px">
                <Stack gap="10px">
                  <Heading order={1} styleOrder={4} tt="uppercase">
                    {signUp ? t("common.signUp") : t("common.login")}
                  </Heading>
                  <Text color="secondary">Velkommen tilbake, det er hyggelig å se deg igjen</Text>
                </Stack>
                <TextField
                  id="txt-loginCheckEmail"
                  label={t("common.email")}
                  defaultValue={emailParam}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  errormessage={validationErrors.email}
                />
                <CTA
                  id="btn-loginCheckEmail"
                  type="submit"
                  className="submit"
                  size="lg"
                  onClick={handleSubmit}
                  fullWidth={true}
                >
                  {t("common.login")}
                </CTA>
              </Stack>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginCheckEmail;
