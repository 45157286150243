import { useTranslation } from "react-i18next";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import EditOrderDetails from "./EditOrderDetails";
import Group from "../../atoms/Group";
import Text from "../../atoms/Text";

import styles from "./styles.module.css";

interface Props {
  selectedActiveOrderBox: any;
  handleActiveOrder: (order: any) => void;
  refreshActiveOrdersCB: () => Promise<void>;
}

export default function EditOrder(props: Props) {
  const { t } = useTranslation();
  const { selectedActiveOrderBox, handleActiveOrder, refreshActiveOrdersCB } = props;

  return (
    <div className={styles.editOrderRoot}>
      <div className={styles.editOrderInner}>
        <Group align="center">
          <SVGAsset onClick={() => handleActiveOrder(null)} name="arrow-left" />
          <Text size="md" display="technical" tt="uppercase" strong={true}>
            {t("commonOrders.activeRequests")}
          </Text>
        </Group>
        <EditOrderDetails
          order={selectedActiveOrderBox}
          handleActiveOrder={handleActiveOrder}
          refreshActiveOrdersCB={refreshActiveOrdersCB}
        />
      </div>
    </div>
  );
}
