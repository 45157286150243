import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Text from "../Text";
import styles from "./styles.module.css";
import SVGAsset from "../../../SVGAsset/SVGAsset";

export interface SettingsNavItemProps {
  href: string;
  text: string;
  isActive?: boolean;
  icon: string;
}

const SettingsNavItem: FC<PropsWithChildren<SettingsNavItemProps>> = ({ href, text, isActive, icon }) => {
  return (
    <li className={`${styles.settingsNavItemRoot}`}>
      <Link to={href} className={`${styles.link} ${isActive ? styles.active : ""}`}>
        <SVGAsset name={icon} />
        <Text size="sm" span={true} display="technical" tt="uppercase" strong={true}>
          {text}
        </Text>
      </Link>
    </li>
  );
};

export default SettingsNavItem;
