import { CSSProperties, PropsWithChildren } from "react";
import LockIcon from "../../atoms/Icons/Lock";
import NetworkIcon from "../../atoms/Icons/Network";

export const Wrapper = (props: PropsWithChildren) => {
  const style: CSSProperties = {
    position: "absolute",
    right: 0,
    top: 0,
    color: "var(--color-grey-75)",
    height: "100%",
    display: "flex",
  };
  return <div style={style}>{props.children}</div>;
};

export const TableIcon = (props: PropsWithChildren) => {
  const style: CSSProperties = {
    width: "36px",
    height: "100%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "var(--border-highlight-normal)",
  };
  return <span style={style}>{props.children}</span>;
};

export const TableIconLocked = () => {
  return (
    <Wrapper>
      <TableIcon>
        <LockIcon width="18" height="18" />
      </TableIcon>
    </Wrapper>
  );
};

export const TableIconsLockedNetwork = () => {
  return (
    <Wrapper>
      <TableIcon>
        <LockIcon width="18" height="18" />
      </TableIcon>
      <TableIcon>
        <NetworkIcon width="18" height="18" />
      </TableIcon>
    </Wrapper>
  );
};
