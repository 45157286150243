import React, { FC, PropsWithChildren } from "react";
import CreateOrderCta from "../CreateOrderCta";
import OrderCard from "../../organisms/OrderCard";
import styles from "./styles.module.css";
import { Order } from "types";

interface Props {
  orders: Order[];
  deleteOrder: (orderId: number) => void;
  createNew: () => void;
  handleActiveOrder: (order: Order) => void;
}

const ActiveOrders: FC<Props> = ({ orders, deleteOrder, createNew, handleActiveOrder }) => {
  return (
    <Wrap>
      <CreateOrderCta onClick={createNew} />

      {orders.map((order: any, i: number) => {
        return (
          <OrderCard
            order={order}
            key={"active-order-box-" + i}
            handleActiveOrder={handleActiveOrder}
            deleteOrder={deleteOrder}
          />
        );
      })}
    </Wrap>
  );
};

const Wrap: FC<PropsWithChildren> = (props) => {
  return (
    <div className={styles.activeOrdersRoot}>
      <div className={styles.inner}>{props.children}</div>
    </div>
  );
};

export default ActiveOrders;
