import { FC } from "react";
import styles from "./styles.module.css";
import Text from "../Text";

interface Props {
  count: number;
}

const StepCount: FC<Props> = ({ count }) => {
  return (
    <div className={styles.stepCountRoot}>
      <Text size="sm" display="technical" strong={true}>
        {count}
      </Text>
    </div>
  );
};

export default StepCount;
