import React, { FC, PropsWithChildren } from "react";
import { Route, useRouteMatch } from "react-router";
import { Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import SettingsNavigation from "../../molecules/SettingsNavigation";

import AccountSettings from "./Account";
import CompanySettings from "./Company";
import UsersSettings from "./Users";
import TemplateSettings from "./Templates";
import NetworkSettings from "./Network";
import NewsletterSettings from "./Newsletter";

import styles from "./styles.module.css";

interface Props {
  setUserContext: (userContext: any) => void;
  changeLocale: (locale: any, i18n: any, timeZone: any) => void;
}

export default function Settings(props: Props) {
  const { t } = useTranslation();
  const { setUserContext, changeLocale } = props;
  const { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Reviver - {t("settings.settings")}</title>
        <meta name="description" content="Reviver - {t('settings.settings')}" />
      </Helmet>

      <SettingsContainer>
        <SettingsMenu>
          <SettingsNavigation />
        </SettingsMenu>
        <SettingsContent>
          <Switch>
            <Route path={`${path}/account`}>
              <AccountSettings changeLocale={changeLocale} />
            </Route>
            <Route path={`${path}/settings`}>
              <CompanySettings setUserContext={setUserContext} changeLocale={changeLocale} />
            </Route>
            <Route path={`${path}/team`} component={UsersSettings} />
            <Route path={`${path}/orderTemplates`} component={TemplateSettings} />
            <Route path={`${path}/network`} component={NetworkSettings} />
            <Route path={`${path}/newsletter`} component={NewsletterSettings} />
          </Switch>
        </SettingsContent>
      </SettingsContainer>
    </>
  );
}

export const SettingsContainer: FC<PropsWithChildren> = (props) => (
  <div className={styles.settingsRoot}>
    <div className={styles.settingsInner}>{props.children}</div>
  </div>
);
export const SettingsMenu: FC<PropsWithChildren> = (props) => <div className={styles.settingsMenu} {...props} />;
export const SettingsContent: FC<PropsWithChildren> = (props) => <div className={styles.settingsContent} {...props} />;
export const SettingsFormContent: FC<PropsWithChildren> = (props) => (
  <div className={styles.settingsFormContent} {...props} />
);
export const SettingsFormCTAs: FC<PropsWithChildren> = (props) => (
  <div className={styles.settingsFormCtas} {...props} />
);
